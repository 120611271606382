// @ts-strict-ignore
import { WidgetFrame } from "components/layout/WidgetFrame";
import { WidgetWithQueryParameters } from "components/layout/WidgetFrame/widget.types";
import { KPI, getLastValueTitle, toKPIChartDataPoints } from "components/ui/KPI";
import { useDataSource } from "hooks/useDataSource";
import { routes } from "routes";
import { generatePath } from "routes/routes.utils";

type Props = WidgetWithQueryParameters<"dateFrom" | "dateTo">;

export function CriticalAlarmsPerAstronautPast12MonthsKPIWidget({ queryParameters }: Props) {
    const title = "Critical Alarms per Astronaut";
    const { dateFrom, dateTo } = queryParameters;

    const chartDataSource = useDataSource("Dashboard_CriticalAlarmsPerAstronautPast12Months_Chart", queryParameters);

    const { data, lastDataPoint } = toKPIChartDataPoints(chartDataSource?.data ?? []);

    return (
        <>
            <WidgetFrame.Title
                title={title}
                subtitle={"12 month averages"}
                icon={"lely-icon-ongoing"}
                size={"sm"}
                link={generatePath(routes.criticalAlarmsAnalysis)}
            >
                <WidgetFrame.Title.Information lastImportType={"middleware"} pageId={1270775885}>
                    Every data-point represents the average of the last 12 months from that date.
                </WidgetFrame.Title.Information>
            </WidgetFrame.Title>

            <WidgetFrame.KPI dependencies={[chartDataSource]}>
                <KPI.LastValue title={getLastValueTitle(dateFrom, dateTo)} value={lastDataPoint?.label} />
                <KPI.Chart data={data} />
            </WidgetFrame.KPI>
        </>
    );
}
