// @ts-strict-ignore
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { TableContext } from "components/components-deprecated/tables/components/BasicTable";
import OrderColumnHeaderCell from "components/components-deprecated/tables/components/OrderColumnHeaderCell";
import { getHeadersForLevel } from "components/components-deprecated/tables/tables.helper";
import { Flex } from "components/ui-deprecated";
import { DataWrapper } from "deprecated/data-wrapper/data-wrapper.interfaces";
import { TranslationKey } from "locales/types";

export interface HeaderProps {
    level: number;
    data: DataWrapper;
    grouping: boolean;
}

const Headers: (props: HeaderProps) => JSX.Element = (props: HeaderProps) => {
    const {
        hiddenColumns,
        formatter,
        orderField,
        orderDirection,
        setOrderField,
        setOrderDirection,
        expandable,
        doubleExpandable,
        expandedQueryParameters,
        expandableStaticData,
        widerColumns,
        addHeaders,
    } = useContext(TableContext);

    const { level, data, grouping } = props;

    const { t } = useTranslation();

    const headers = data !== undefined ? getHeadersForLevel(data, hiddenColumns, level, grouping, addHeaders) : [];
    const accountForToggle =
        (expandable && (expandedQueryParameters || expandableStaticData) && level === 0) ||
        (doubleExpandable && level === 1);

    // TODO: Add more conditions here, e.g. setting from config
    const sortable = level === 0;

    return (
        <Flex className={`basic-table-row header-row ${grouping ? "grouping" : ""}`}>
            {accountForToggle && <Flex className={"toggle-button-wrapper"} />}
            {sortable
                ? headers.map((field) => (
                      <Flex
                          className={`basic-table-cell header-cell ${widerColumns.includes(field) ? "wider" : ""}`}
                          key={field}
                      >
                          {(() => {
                              if (orderField === field) {
                                  if (orderDirection === "ASC") {
                                      return (
                                          <OrderColumnHeaderCell
                                              onClick={() => setOrderDirection("DESC")}
                                              formatter={formatter}
                                              field={field}
                                              setIcon={"caret-up"}
                                          />
                                      );
                                  } else {
                                      return (
                                          <OrderColumnHeaderCell
                                              onClick={() => {
                                                  setOrderField(null);
                                                  setOrderDirection("ASC");
                                              }}
                                              formatter={formatter}
                                              field={field}
                                              setIcon={"caret-down"}
                                          />
                                      );
                                  }
                              } else {
                                  return (
                                      <OrderColumnHeaderCell
                                          onClick={() => {
                                              setOrderField(field);
                                              setOrderDirection("ASC");
                                          }}
                                          formatter={formatter}
                                          field={field}
                                          setIcon={"sort"}
                                      />
                                  );
                              }
                          })()}
                      </Flex>
                  ))
                : headers.map((field) => (
                      <Flex
                          className={`basic-table-cell header-cell ${widerColumns.includes(field) ? "wider" : ""}`}
                          key={field}
                      >
                          <div>{t(formatter(field) as TranslationKey)}</div>
                      </Flex>
                  ))}
        </Flex>
    );
};

export default Headers;
