import { css } from "styled-components";

export const confluenceCssOverrides = css`
    p,
    ul {
        margin: 0;
    }

    hr {
        width: 100%;
        height: 1px;
        flex-shrink: 0;
        border: 0;
        background-color: var(--wds-color-border);
    }

    // Target Confluence-defined class for centering images
    .image-center-wrapper {
        display: block;
        max-width: 100%;
        text-align: center;

        img {
            max-width: 100%;
        }
    }
`;
