// @ts-strict-ignore
import { ChartConfiguration } from "components/ui/Chart/chart.types";
import { baseChartOptions } from "components/ui/Chart/configurations/chart-configurations/base.chart-configuration";
import { createLegendConfiguration } from "components/ui/Chart/configurations/plugins/legend.plugin-configuration";
import { createTooltipConfiguration } from "components/ui/Chart/configurations/plugins/tooltip.plugin-configuration";

export const pieChartConfiguration: ChartConfiguration<"pie"> = {
    type: "pie",
    options: {
        ...baseChartOptions,
        plugins: {
            legend: createLegendConfiguration(),
            tooltip: createTooltipConfiguration({
                intersect: true,
            }),
        },
    },
};

type CreatePieChartConfigurationOptions = {
    plugins?: ChartConfiguration<"pie">["options"]["plugins"];
};

export const createPieChartConfiguration = (
    options: CreatePieChartConfigurationOptions
): ChartConfiguration<"pie"> => ({
    ...pieChartConfiguration,
    options: {
        ...pieChartConfiguration.options,
        plugins: {
            ...options?.plugins,
        },
    },
});
