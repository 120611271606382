// @ts-strict-ignore
import { CSVLink } from "react-csv";

import { Flex } from "components/ui-deprecated";
import type { Value } from "deprecated/data-wrapper";

import "deprecated/data-wrapper";

export type CSVExportProps = {
    data: Array<Record<string, Value>>;
    filename: string;
    text: string;
};

CSVExport.defaultProps = {
    data: [],
    filename: "data.csv",
    text: "Export CSV",
};

export default function CSVExport(props: CSVExportProps) {
    const { data, filename, text } = props;
    return (
        <Flex flexDirection={"column"}>
            <CSVLink data={data} filename={filename}>
                {text}
            </CSVLink>
        </Flex>
    );
}
