// @ts-strict-ignore
import { ChartDataset, ChartTypeRegistry } from "chart.js";
import moment from "moment";
import styled from "styled-components";

import {
    Chart,
    chartColor,
    ChartData,
    createThresholdLineDataset,
    type TooltipPluginConfiguration,
} from "components/ui/Chart";
import { KPINoData } from "components/ui/KPI/KPINoData";
import { createKPIChartConfiguration } from "components/ui/KPI/charts/KPIChart/kpi-chart.configuration";
import { createFillerDataset, createKPIDataset } from "components/ui/KPI/charts/KPIChart/kpi-chart.dataset";
import { filterThresholds } from "components/ui/KPI/charts/KPIChart/kpi-chart.threshold";
import { KPIChartDataPoint, KPIChartThreshold } from "components/ui/KPI/charts/KPIChart/kpi-chart.types";
import { KPIColor, THRESHOLD_DATASET_ID } from "components/ui/KPI/kpi.consts";
import { getKPITimeScale, resolveKPIColor } from "components/ui/KPI/kpi.utils";

const ChartContainer = styled.div`
    grid-column: span 2;
    overflow: hidden;
    height: 100%;
`;

type Props = {
    color?: KPIColor;
    data: KPIChartDataPoint[];
    getLabel?: TooltipPluginConfiguration["callbacks"]["label"];
    thresholds?: KPIChartThreshold[];
    useISOWeek?: boolean;
};

export const KPIChart = (props: Props) => {
    const { data: rawData, getLabel, useISOWeek } = props;
    if (!rawData) return <KPINoData />;
    if (rawData.length < 2) return <KPINoData fallbackText={"Not enough data"} />;

    const data = rawData.toSorted((a, b) => moment(a.date).diff(moment(b.date)));

    const color = resolveKPIColor(props?.color);
    const lastValue = data.at(-1);
    const timeScale = getKPITimeScale(data[0].date, lastValue.date);

    const thresholds = filterThresholds({
        dataMin: Math.min(...data.map(({ value }) => value)),
        dataMax: Math.max(...data.map(({ value }) => value)),
        thresholds: props.thresholds,
        lastValue: lastValue.value,
    });

    const comparisonValues: number[] = data.map(({ value }) => value);
    const thresholdsValues = thresholds.map(({ value }) => value);
    if (thresholdsValues.length > 0) {
        comparisonValues.push(...thresholdsValues);
    }

    const suggestedMax = Math.max(...comparisonValues);
    const suggestedMin = Math.min(...comparisonValues);

    const daysBetweenDates = moment(lastValue!.date).diff(moment(data.at(-2)!.date), "days");
    const nextDate = moment(lastValue.date).add(daysBetweenDates, "days").format();

    const configuration = createKPIChartConfiguration({
        suggestedMax,
        suggestedMin,
        timeScale,
        getLabel:
            getLabel !== undefined
                ? getLabel
                : ({ raw }: any) => {
                      return `Value: ${raw.label}`;
                  },
        useISOWeek,
    });

    const thresholdsDatasets: ChartDataset<keyof ChartTypeRegistry, any>[] =
        thresholds?.map((threshold) =>
            createThresholdLineDataset({
                id: `${THRESHOLD_DATASET_ID}-${threshold.value}`,
                data: Array(data.length + 1).fill(threshold.value),
                color: threshold.color,
                hidden: true,
            })
        ) ?? [];

    const chartData: ChartData = {
        labels: data.map(({ date }) => date).concat(nextDate),
        datasets: [
            ...thresholdsDatasets,
            createKPIDataset({
                label: "Value",
                data: data.map(({ date, value, label }) => ({
                    x: date,
                    y: value,
                    label: label ?? value,
                })),
                gradient: true,
                color: color,
            }),
            // Grey end dataset
            createFillerDataset({
                data: data,
                value: lastValue.value,
                color: chartColor.lightGrey,
            }),
        ],
    };

    return (
        <ChartContainer>
            <Chart configuration={configuration} data={chartData} />
        </ChartContainer>
    );
};
