import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { resources, namespaces } from "locales/resources";

i18n.use(initReactI18next).init({
    resources,
    defaultNS: "translation",
    fallbackNS: "translation",
    ns: namespaces,
    lng: "en",
    interpolation: {
        escapeValue: false, // react already safes from xss
    },
});
export default i18n;
