import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import { uniqBy } from "lodash";
import { PersistConfig } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { userApi } from "store/services/user.service";
import { User, UserAreaName, UserCluster, UserRole } from "types/user.types";

export enum Units {
    Metric = "Metric",
    Imperial = "Imperial",
}

type State = Pick<User, "id" | "name" | "login" | "cluster" | "company" | "selectedClusters" | "permissions"> & {
    primaryCombinedRole: UserRole;
    primaryActiveRoleId: string | null;
    secondaryRoles: Omit<UserRole, "children">[];
    secondaryActiveRolesIds: string[] | null;
    areas: UserAreaName[];
    language: string;
    units: Units;
    developerMode: boolean;
};

const setDefaultPrimaryActiveRoleId = (state: State) => {
    const { primaryCombinedRole, primaryActiveRoleId } = state;

    const primaryRoles = primaryCombinedRole.children.filter((role) => role.type === "primary");

    // Selection of active primary role, in order of priority:
    // 1) persisted primary role,
    // 2) main role of primary combined role,
    // 3) first primary role of primary combined role.
    let primaryActiveRole = primaryRoles.find((role) => role.id === primaryActiveRoleId);
    if (!primaryActiveRole) {
        primaryActiveRole = primaryRoles.find((role) => role.id === primaryCombinedRole.mainRoleId);
    }
    if (!primaryActiveRole) {
        primaryActiveRole = primaryRoles.at(0);
    }
    if (!primaryActiveRole) {
        throw new Error("No primary role found");
    }
    state.primaryActiveRoleId = primaryActiveRole.id;
};

const setDefaultSecondaryActiveRoleIds = (state: State) => {
    const { secondaryRoles, secondaryActiveRolesIds } = state;

    // If no persisted secondary roles, all secondary roles are active.
    if (secondaryActiveRolesIds === null) {
        state.secondaryActiveRolesIds = secondaryRoles.map((role) => role.id);
    } else {
        state.secondaryActiveRolesIds = secondaryActiveRolesIds.filter((activeRoleId) =>
            secondaryRoles.some((role) => role.id === activeRoleId)
        );
    }
};

const getAreasByActiveRolesIds = (state: State) => {
    const { primaryCombinedRole, primaryActiveRoleId, secondaryRoles, secondaryActiveRolesIds } = state;

    const getAreas = (role: Omit<UserRole, "children">) => role.areas.map((area) => area.name);

    let activeAreas: UserAreaName[] = [];

    activeAreas = [...activeAreas, ...getAreas(primaryCombinedRole)];

    const activePrimaryRole = primaryCombinedRole.children.find((role) => role.id === primaryActiveRoleId);
    if (activePrimaryRole) {
        activeAreas = [...activeAreas, ...getAreas(activePrimaryRole)];
    }

    const activeSecondaryRoles = secondaryRoles.filter(
        (role) => secondaryActiveRolesIds && secondaryActiveRolesIds.includes(role.id)
    );
    activeAreas = [...activeAreas, ...activeSecondaryRoles.flatMap(getAreas)];

    return activeAreas;
};

const defaultLanguage = "en";
const defaultUnits = Units.Metric;
const initialState: State = {
    id: "",
    name: "",
    primaryCombinedRole: {} as any,
    primaryActiveRoleId: null,
    secondaryRoles: [],
    secondaryActiveRolesIds: null,
    areas: [],
    selectedClusters: [],
    language: defaultLanguage,
    permissions: {},
    units: defaultUnits,
    developerMode: false,
};

const persistConfig: PersistConfig<State> = {
    key: "lssa:user",
    storage: storage,
    whitelist: ["primaryActiveRoleId", "secondaryActiveRolesIds", "developerMode"],
};

const slice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setPrimaryActiveRoleId: (state, action: PayloadAction<string>) => {
            state.primaryActiveRoleId = action.payload;
            state.areas = getAreasByActiveRolesIds(state);
        },
        setSecondaryActiveRoleIds: (state, action: PayloadAction<string[]>) => {
            state.secondaryActiveRolesIds = action.payload;
            state.areas = getAreasByActiveRolesIds(state);
        },
        setLanguage: (state, action: PayloadAction<string>) => {
            state.language = action.payload;
        },
        setUnits: (state, action: PayloadAction<Units>) => {
            state.units = action.payload;
        },
        setClusters: (state, action: PayloadAction<UserCluster[]>) => {
            state.selectedClusters = action.payload;
        },
        toggleDeveloperMode: (state) => {
            state.developerMode = !state.developerMode;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(userApi.endpoints.getUser.matchFulfilled, (state, { payload: user }) => {
            const { id, name, userSettings } = user;

            state.id = id;
            state.name = name;
            state.cluster = user.cluster;
            state.company = user.company;

            // We collect the user data on all environments for now
            Sentry.setUser({ id, username: name });

            const primaryCombinedRoles = user.roles.filter((role) => role.type === "primary");
            const primaryCombinedRole = primaryCombinedRoles[0];
            state.primaryCombinedRole = primaryCombinedRoles[0];
            let secondaryRoles = [
                ...user.roles.filter((role) => role.type === "secondary"),
                ...state.primaryCombinedRole.children.filter((role) => role.type === "secondary"),
            ];
            secondaryRoles = uniqBy(secondaryRoles, "id");
            state.secondaryRoles = secondaryRoles;

            // Use primary active roles stored in user settings, or set a default value
            const primaryActiveRoleId: string | null =
                userSettings.find((setting) => setting.name === "primaryActiveRoleId")?.value ?? null;
            // Check if the stored role is a valid primary role
            if (primaryActiveRoleId && primaryCombinedRole.children.some((role) => role.id === primaryActiveRoleId)) {
                state.primaryActiveRoleId = primaryActiveRoleId;
            } else {
                setDefaultPrimaryActiveRoleId(state);
            }

            // Use secondary active roles stored in user settings, or set default values
            // Secondary roles are stored as a comma-separated string
            const secondaryActiveRolesIds: string[] | null =
                userSettings.find((setting) => setting.name === "secondaryActiveRolesIds")?.value?.split(",") ?? null;
            if (secondaryActiveRolesIds) {
                // Check if the stored roles are valid secondary roles
                state.secondaryActiveRolesIds = secondaryActiveRolesIds.filter((activeRoleId) =>
                    secondaryRoles.some((role) => role.id === activeRoleId)
                );
            } else {
                setDefaultSecondaryActiveRoleIds(state);
            }

            state.areas = getAreasByActiveRolesIds(state);

            state.selectedClusters = user.selectedClusters;

            const language = userSettings.find((setting) => setting.name === "language")?.value;
            state.language = language ?? defaultLanguage;

            state.permissions = user.permissions;

            const units = userSettings.find((setting) => setting.name === "units")?.value;
            // TODO: Remove the duplicate "Units" definition in native enum, use the schema instead
            state.units = (units as Units) ?? defaultUnits;

            Sentry.setTags({
                ["user.cluster"]: state.cluster,
                ["user.company"]: state.company,
                ["user.language"]: state.language,
                ["user.login"]: state.login,
                ["user.primaryCombinedRole"]: state.primaryCombinedRole.name,
                ["user.primaryActiveRole"]: state.primaryCombinedRole.children.find(
                    (role) => role.id === state.primaryActiveRoleId
                )?.name,
                ["user.secondaryRoles"]: state.secondaryRoles.map((role) => role.name).join(", "),
                ["user.units"]: state.units,
            });
        });
    },
});

export const {
    setPrimaryActiveRoleId,
    setSecondaryActiveRoleIds,
    setLanguage,
    setUnits,
    setClusters,
    toggleDeveloperMode,
} = slice.actions;
export const { reducer: userReducer } = slice;
export { persistConfig as userPersistConfig };
