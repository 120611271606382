// @ts-strict-ignore
import { createSelector } from "@reduxjs/toolkit";

export const primaryRolesSelector = createSelector(
    [
        (state) => {
            return state.user.primaryCombinedRole.children;
        },
    ],
    (state) => {
        return state.filter((role) => role.type === "primary");
    }
);
