// @ts-strict-ignore
import { WidgetFrame } from "components/layout/WidgetFrame";
import { WidgetWithQueryParameters } from "components/layout/WidgetFrame/widget.types";
import {
    type Regions,
    KPI,
    getLastValueTitle,
    getThresholdsFromRegions,
    getActiveRegion,
    toKPIChartDataPoints,
} from "components/ui/KPI";
import { useDataSource } from "hooks/useDataSource";
import { routes } from "routes";
import { generatePath } from "routes/routes.utils";

type DataRow = {
    ChartDate: string;
    ChartValue: number;
};

type TextDataRow = {
    DsThresholdHigher: number;
    DsThresholdLower: number;
};

type Props = WidgetWithQueryParameters<"dateFrom" | "dateTo">;

const AbnormalPMTimesKPIWidget = (props: Props) => {
    const { queryParameters } = props;
    const { dateFrom, dateTo } = queryParameters;

    const title = "Abnormal Preventive Maintenance Times";
    const subtitle = "Astronauts";

    const dataSourceParameters = { ...queryParameters, KPI: 11, technicianCode: "" };

    const chartDataSource = useDataSource<DataRow[]>("Dashboard_SpentHours_Chart", dataSourceParameters);
    const textDataSource = useDataSource<[TextDataRow]>("Dashboard_SpentHours_Text", dataSourceParameters);
    const { DsThresholdHigher, DsThresholdLower } = textDataSource?.data?.[0] ?? {};

    const { data, lastDataPoint } = toKPIChartDataPoints(chartDataSource?.data ?? []);

    const regions = [
        { type: "region", color: "red" },
        { type: "threshold", value: DsThresholdHigher, regionIfEqual: "upper" },
        { type: "region", color: "orange" },
        { type: "threshold", value: DsThresholdLower, regionIfEqual: "upper" },
        { type: "region", color: "green" },
    ] satisfies Regions;
    const thresholds = getThresholdsFromRegions({ regions, lastDataPoint });
    const activeRegion = getActiveRegion({ regions, lastDataPoint });
    const color = activeRegion?.color;

    return (
        <>
            <WidgetFrame.Title
                title={title}
                subtitle={subtitle}
                icon={"lely-icon-diagram"}
                size={"sm"}
                link={generatePath(routes.abnormalPreventiveMaintenanceTimes)}
            >
                <WidgetFrame.Title.Information lastImportType={"middleware"} pageId={207487126}>
                    For a definition of Abnormal PM click this (i) icon.
                </WidgetFrame.Title.Information>
            </WidgetFrame.Title>
            <WidgetFrame.KPI dependencies={[chartDataSource, textDataSource]}>
                <KPI.LastValue title={getLastValueTitle(dateFrom, dateTo)} value={lastDataPoint?.label} color={color} />
                <KPI.Chart data={data} color={color} thresholds={thresholds} />
            </WidgetFrame.KPI>
        </>
    );
};

export { AbnormalPMTimesKPIWidget };
