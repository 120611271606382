import { ComponentProps, forwardRef, useMemo } from "react";
import styled from "styled-components";

import { WidgetFrame } from "components/layout/WidgetFrame";
import { WidgetFrameTableExpandableProps } from "components/layout/WidgetFrame/WidgetFrameTableExpandable";
import { TableHeadExpandable } from "components/ui/table-final-saviour/Table/TableHead";
import { Row } from "components/ui/table-final-saviour/Table/table.types";

const WidgetFrameTableExpandableStyled = styled(WidgetFrame.TableExpandable)`
    border-bottom: 1px solid ${(props) => props.theme.color.grayDairyLight};
`;

const TableHeadExpandableLvl2 = forwardRef<HTMLTableSectionElement, ComponentProps<typeof TableHeadExpandable>>(
    (props, ref) => {
        return <TableHeadExpandable {...props} ref={ref} zIndex={2} />;
    }
);
TableHeadExpandableLvl2.displayName = "TableHeadExpandableLvl2";

type Props<T> = WidgetFrameTableExpandableProps<T>;

const ExpandedContentSectionTableExpandable = <T extends Row>(props: Props<T>) => {
    const components = useMemo(
        () => ({
            ...(props.components ?? {}),
            TableHead: TableHeadExpandableLvl2,
        }),
        [props.components]
    );

    return <WidgetFrameTableExpandableStyled {...props} components={components} />;
};

export { ExpandedContentSectionTableExpandable };
