import { WidgetFrame } from "components/layout/WidgetFrame";
import { WidgetWithQueryParameters } from "components/layout/WidgetFrame/widget.types";
import {
    type ChartConfiguration,
    type ChartData,
    chartColor,
    createRadarChartDataset,
    createRadarChartConfiguration,
    createRadialLinearAxis,
} from "components/ui/Chart";
import { useDataSource } from "hooks/useDataSource";

const kpiTypeLabelMap = {
    kpi_short_interval_low_yield: "Short intervals (<=5%)",
    kpi_connection_attempts_avg: "Connection attempts (<=1.3)",
    kpi_free_time_percentage: "Free time (>10%)",
    kpi_failures_per_robot: "Failed milkings (<=5)",
    kpi_refusals_per_cow: "Refusals (>1)",
};

const radarChartConfiguration = createRadarChartConfiguration({
    getTotalValue: (value) => `${value}%`,
});

type Props = WidgetWithQueryParameters<"dateTo" | "dateFrom">;

// Source: FMSDashboard_MilkingSpiderWeb_VegaLiteWidget.json
// id: 6f16c8a9-dc0f-4a2a-849f-09a9bd9593e4
const MilkingsKPIFulfillmentWidget = (props: Props) => {
    const { queryParameters } = props;
    const title = "Milkings KPI Fulfillment";

    const dataDataSource = useDataSource("FMS_Dashboard_MilkingKpiOverview", {
        ...queryParameters,
    });
    const mainDataSources = [dataDataSource];

    const data = dataDataSource?.data ?? [];

    const chartData: ChartData<"radar"> = {
        labels: Object.values(kpiTypeLabelMap),
        datasets: [
            createRadarChartDataset({
                data: Object.keys(kpiTypeLabelMap).map((key) => data[0]?.[key]),
                color: chartColor.red,
            }),
        ],
    };

    const chartConfiguration: ChartConfiguration<"radar"> = {
        ...radarChartConfiguration,
        options: {
            ...radarChartConfiguration.options,
            scales: {
                r: createRadialLinearAxis({
                    max: 100,
                }),
            },
        },
    };

    return (
        <>
            <WidgetFrame.Title title={title} icon={"lely-icon-reports"}>
                <WidgetFrame.Title.Information lastImportType={"middleware"} pageId={1097203713}>
                    Percentage of farms fulfilling the requirements
                </WidgetFrame.Title.Information>
            </WidgetFrame.Title>
            <WidgetFrame.Chart configuration={chartConfiguration} data={chartData} dependencies={mainDataSources} />
        </>
    );
};

export { MilkingsKPIFulfillmentWidget };
