import { fetchBaseQuery, createApi } from "@reduxjs/toolkit/query/react";

import { config } from "config";
import { baseQueryWithAuthRefresh, prepareHeaders } from "store/base-query";
import { fmsReportBaseQuery } from "store/fms-report-base-query";

export const api = createApi({
    baseQuery: baseQueryWithAuthRefresh,
    endpoints: () => ({}),
});

export const apiJS = createApi({
    reducerPath: "apiJS",
    baseQuery: fetchBaseQuery({
        baseUrl: `${config.API_JS_URL}`,
        prepareHeaders,
    }),
    endpoints: () => ({}),
});

export const fmsReportApi = createApi({
    baseQuery: fmsReportBaseQuery,
    reducerPath: "fmsReportApi",
    endpoints: () => ({}),
});
