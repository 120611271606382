// @ts-strict-ignore
import { memo } from "react";

import { TableHeaderCellProps } from "components/ui/table-final-saviour/Table/TableHeaderCell/TableHeaderCell";
import {
    TableHeaderCellSortableStyled,
    TableHeaderCellSortableContent,
    TableHeaderCellSortableSortIcon,
} from "components/ui/table-final-saviour/Table/TableHeaderCell/TableHeaderCellSortable/table-header-cell-sortable.styled-components";
import { SortOrder } from "components/ui/table-final-saviour/Table/TableHeaderCell/TableHeaderCellSortable/table-header-cell-sortable.types";
import { sortIconMap } from "components/ui/table-final-saviour/Table/TableHeaderCell/TableHeaderCellSortable/table-header-cell-sortable.utils";
import { Row } from "components/ui/table-final-saviour/Table/table.types";

interface Props<T extends Row> extends TableHeaderCellProps<T> {
    sortOrder: SortOrder;
    onClick: () => void;
}

const TableHeaderCellSortable = <T extends Row>(props: Props<T>) => {
    const { align, sortOrder } = props;
    const icon = sortIconMap[sortOrder];

    const justifyContentMap = {
        left: "flex-start",
        center: "center",
        right: "flex-end",
    };
    const justifyContent = align ? justifyContentMap[align] : undefined;

    return (
        <TableHeaderCellSortableStyled
            className={props.className}
            $align={props.align}
            $width={props.width}
            onClick={props.onClick}
        >
            <TableHeaderCellSortableContent $justifyContent={justifyContent}>
                {props.children}
                <TableHeaderCellSortableSortIcon icon={icon} />
            </TableHeaderCellSortableContent>
        </TableHeaderCellSortableStyled>
    );
};

const TableHeaderCellSortableMemo = memo(TableHeaderCellSortable);

export { TableHeaderCellSortableMemo as TableHeaderCellSortable };
export type { Props as TableHeaderCellSortableProps };
