import { useCallback, useState } from "react";

import { GetRowId, Row } from "components/ui/table-final-saviour/Table/table.types";

type Options<T extends Row> = {
    getRowId: GetRowId<T>;
};

export const useExpandableRows = <T extends Row>(options: Options<T>) => {
    const { getRowId } = options;

    const [expandedRows, setExpandedRow] = useState<Set<string | number>>(new Set());

    const getIsExpandedRow = useCallback(
        (row: T) => {
            const rowId = getRowId(row);
            return expandedRows.has(rowId);
        },
        [expandedRows, getRowId]
    );

    const toggleExpandedRow = useCallback(
        (row: T) => {
            const rowId = getRowId(row);
            setExpandedRow((prev) => {
                const curr = new Set(prev);
                if (prev.has(rowId)) {
                    curr.delete(rowId);
                } else {
                    curr.add(rowId);
                }
                return curr;
            });
        },
        [getRowId]
    );

    const expandedRowsStringified = Array.from(expandedRows).join(",");
    /**
     * Updates the set of expanded rows based on the new data.
     * It keeps only the rows that are still present in the new data,
     * ensuring that the expanded state is consistent with the current data set.
     */
    const updateExpandedRows = useCallback(
        (rows: T[]) => {
            const newRowIds = new Set(rows.map(getRowId));
            // Keep the expanded rows that are still in the new data
            const existingRowIds = expandedRows.intersection(newRowIds);

            setExpandedRow(existingRowIds);
        },
        // We can't use expandedRows here, because it's a Set and it's not serializable
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [expandedRowsStringified, getRowId]
    );

    return { getIsExpandedRow, toggleExpandedRow, updateExpandedRows };
};
