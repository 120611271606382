// @ts-strict-ignore
import { WidgetFrame } from "components/layout/WidgetFrame";
import { WidgetWithQueryParameters } from "components/layout/WidgetFrame/widget.types";
import {
    type ChartData,
    chartColor,
    createTimeXAxis,
    createLinearYAxis,
    createLineChartDataset,
    createLineChartConfiguration,
} from "components/ui/Chart";
import { useDataSource } from "hooks/useDataSource";
import { routes } from "routes";
import { generatePath } from "routes/routes.utils";

type Props = WidgetWithQueryParameters<"dateTo" | "dateFrom">;

// Source: FMSDashboard_VectorSystemFailures_VegaLiteWidget.json
// id: 44dc6ee5-1ad0-4480-9fa3-a12c3e447fff
const VectorSystemFailuresWidget = (props: Props) => {
    const { queryParameters } = props;
    const title = "Vector system failures";

    const dataDataSource = useDataSource("FMS_Dashboard_VectorCriticalAlarms_KPIChart", {
        ...queryParameters,
    });
    const mainDataSources = [dataDataSource];
    const data = dataDataSource?.data ?? [];

    const chartConfiguration = createLineChartConfiguration({
        scales: {
            x: createTimeXAxis(),
            y: createLinearYAxis({ axisLabel: "Failures", beginAtZero: true }),
        },
    });

    const chartData: ChartData = {
        labels: data.map(({ ChartDate }) => ChartDate),
        datasets: [
            createLineChartDataset({
                label: "Kitchen empty",
                data: data.map(({ ChartValue }) => ChartValue),
                color: chartColor.darkTeal,
            }),
            createLineChartDataset({
                label: "Other system failures",
                data: data.map(({ ChartValue2 }) => ChartValue2),
                color: chartColor.red,
            }),
        ],
    };

    return (
        <>
            <WidgetFrame.Title
                title={title}
                icon={"lely-icon-graph"}
                link={generatePath(routes.criticalAlarmsAnalysis, {
                    subRoute: routes.criticalAlarmsAnalysis.subRoutes.vector,
                })}
            >
                <WidgetFrame.Title.Information lastImportType={"middleware"} pageId={1108869174} />
            </WidgetFrame.Title>
            <WidgetFrame.Chart configuration={chartConfiguration} data={chartData} dependencies={mainDataSources} />
        </>
    );
};

export { VectorSystemFailuresWidget };
