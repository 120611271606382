import { CSSProperties, PropsWithChildren } from "react";
import styled, { css } from "styled-components";

const smSizeCSS = css`
    height: 1.5rem;
    margin: -0.125rem 0;

    & .select-input-wrapper > input {
        padding: 0.125rem 0.5rem;
    }
`;

const WidgetFrameTitleSelectStyled = styled.div`
    flex-grow: 1;

    height: 2rem;
    margin: -0.25rem 0;

    & .select-input-wrapper {
        border: 1px solid ${({ theme }) => theme.color.grayDairyLight};
        box-shadow: none;

        & > input {
            // TODO Implement less hacky way once all old table components are removed
            // Needed because of the combination of fixed height and border, which is added here
            padding-top: 7px;
            padding-bottom: 7px;
        }
    }

    ${({ theme: { widgetSize } }) => widgetSize === "sm" && smSizeCSS};
`;

type Props = PropsWithChildren & {
    minWidth?: CSSProperties["minWidth"];
};

const WidgetFrameTitleSelect = (props: Props) => {
    const { children, minWidth } = props;

    return <WidgetFrameTitleSelectStyled style={{ minWidth }}>{children}</WidgetFrameTitleSelectStyled>;
};

export { WidgetFrameTitleSelect };
