import { DocumentationButton } from "features/documentation/DocumentationButton";
import { DocumentationWindow } from "features/documentation/DocumentationWindow/DocumentationWindow";

const Documentation = () => {
    return (
        <>
            <DocumentationButton />
            <DocumentationWindow />
        </>
    );
};

export { Documentation };
