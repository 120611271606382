import { ChartDataset, Plugin } from "chart.js";

import { THRESHOLD_DATASET_ID } from "components/ui/KPI/kpi.consts";
import { theme } from "styles/theme";

type PluginDataset = ChartDataset & {
    id?: string;
};

let animationFrameId: number;

export const showGridOnChartHoverPlugin: Plugin = {
    id: "showGridOnChartHoverPlugin",
    beforeEvent(chart, args) {
        if (!["mouseenter", "mouseout"].includes(args.event.type)) return;

        cancelAnimationFrame(animationFrameId);
        animationFrameId = requestAnimationFrame(() => {
            const showContent = args.event.type === "mouseenter";

            if (chart?.options?.scales?.y?.grid) {
                chart.options.scales.y.grid.color = showContent ? theme.color.grayDairyLight : "transparent";
            }
            if (chart?.options?.scales?.y?.ticks) {
                chart.options.scales.y.ticks.color = showContent ? theme.color.textGray : "transparent";
            }

            chart.data?.datasets?.forEach((dataset: PluginDataset, index) => {
                if (!dataset.id?.includes(THRESHOLD_DATASET_ID)) return;

                if (showContent) {
                    chart.show(index);
                } else {
                    chart.hide(index);
                }
            });
            chart.update();
        });
    },
};
