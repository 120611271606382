// @ts-strict-ignore
import { memo } from "react";
import styled from "styled-components";

import { TableRowProps } from "components/ui/table-final-saviour/Table/TableRow/table-row.types";

const TableRowStyled = styled.tr<{ $rowIndex: number }>`
    border-bottom: 1px solid ${({ theme }) => theme.color.grayDairyLight};

    &:last-child {
        border-bottom: none;
    }
`;

type Props = TableRowProps;

const TableRow = (props: Props) => {
    return (
        <TableRowStyled
            style={props.style}
            $rowIndex={props.rowIndex}
            data-index={props["data-index"]}
            data-item-index={props["data-item-index"]}
            data-known-size={props["data-known-size"]}
            data-row-type={props["data-row-type"]}
        >
            {props.children}
        </TableRowStyled>
    );
};

const TableRowMemo = memo(TableRow);
export { TableRowMemo as TableRow };
