// @ts-strict-ignore
import { useMemo } from "react";

import { Chip, ChipVariant } from "components/ui/Chip";
import { DataContent } from "components/ui/DataContent";
import { Column, createChipsColumn, createDataSortableColumn, useSort } from "components/ui/table-final-saviour/Table";
import { TableCell } from "components/ui/table-final-saviour/Table/TableCell";
import { TableCellLink } from "components/ui/table-final-saviour/Table/TableCell/TableCellLink";
import { ExpandedContent } from "components/ui/table-final-saviour/Table/TableExpandable";
import { createActionButtonsColumn } from "components/ui/table-final-saviour/Table/columns/action-buttons.table-column";
import { ExpandedContentComponent } from "components/ui/table-final-saviour/Table/table-expandable.types";
import { useDataSource } from "hooks/useDataSource";
import { ConfirmInsightButton } from "pages/DashboardPage/tss/widgets/InsightsWidget/ConfirmInsightButton";
import { DeleteInsightButton } from "pages/DashboardPage/tss/widgets/InsightsWidget/DeleteInsightButton";
import {
    InsightsWidgetDataRow,
    InsightsWidgetExpandedDataRow,
    InsightsWidgetExpandedDataSourceRow,
} from "pages/DashboardPage/tss/widgets/InsightsWidget/insights-widget.types";
import { routes } from "routes";
import { generatePath, toDeviceTypeParam } from "routes/routes.utils";

const InsightsWidgetExpandedContent: ExpandedContentComponent<InsightsWidgetDataRow> = (props) => {
    const { row } = props;

    const dataSource = useDataSource<InsightsWidgetExpandedDataSourceRow[]>("Insights_ExpandedDetail", {
        customerNumber: row["CustomerNumber"],
    });
    const { data = [] } = dataSource;

    const { sortControls, applySort } = useSort<InsightsWidgetExpandedDataRow>({
        initialSortConfig: { key: "IssueCount", order: "desc", dataType: "number" },
    });

    const columns: Column<InsightsWidgetExpandedDataRow>[] = useMemo(
        () => [
            createDataSortableColumn({
                dataKey: "Name",
                dataType: "string",
                title: "Device",
                width: 160,
                ...sortControls,
                components: {
                    TableCell: (cellProps) => {
                        const { row } = cellProps;
                        const { DeviceSerialNumber, DevicePageType } = row;
                        return (
                            <TableCellLink
                                {...cellProps}
                                to={generatePath(routes.customerDeviceDetail, {
                                    params: {
                                        customerNumber: row.CustomerNumber,
                                        deviceType: toDeviceTypeParam(DevicePageType),
                                        deviceSerialNumber: DeviceSerialNumber,
                                    },
                                    searchParams: {
                                        insightId: row.InsightId,
                                    },
                                })}
                            />
                        );
                    },
                },
            }),
            createDataSortableColumn({
                dataKey: "ExDaysUntilNextPM",
                dataType: "number",
                title: "Days Until Next PM",
                align: "right",
                width: 120,
                ...sortControls,
            }),
            createChipsColumn({
                title: "Critical and Problematic Areas",
                getChips: (cellProps) => {
                    const { row } = cellProps;
                    const { IssueCategory, IssueLevel } = row;
                    if (!IssueCategory) return <TableCell {...cellProps} />;

                    return IssueCategory.map((category, index) => {
                        const level = IssueLevel[index];
                        let variant: ChipVariant = "default";
                        if (level === "red") {
                            variant = "danger";
                        }

                        return <Chip key={index} label={category} variant={variant} />;
                    });
                },
            }),
            createActionButtonsColumn({
                title: "Actions",
                width: 178,
                getButtons: ({ row }) => (
                    <>
                        <ConfirmInsightButton data={row} />
                        <DeleteInsightButton data={row} />
                    </>
                ),
            }),
        ],
        [sortControls]
    );

    const sortedData = useMemo(() => {
        const parsedData = data.map((row) => {
            const { IssueCategory, IssueLevel } = row;
            const categories = IssueCategory.split(",");
            const levels = IssueLevel.split(",");
            const issueCount = categories.length;

            return {
                ...row,
                IssueCount: issueCount,
                IssueCategory: categories,
                IssueLevel: levels,
            } as InsightsWidgetExpandedDataRow;
        });

        return applySort(parsedData);
    }, [applySort, data]);

    return (
        <ExpandedContent>
            <DataContent dependencies={[dataSource]}>
                <ExpandedContent.Section.Table<InsightsWidgetExpandedDataRow>
                    dataSource={{ ...dataSource, data: sortedData }}
                    columns={columns}
                    maxHeight={400}
                />
            </DataContent>
        </ExpandedContent>
    );
};

export { InsightsWidgetExpandedContent };
