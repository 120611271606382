// @ts-strict-ignore
import { TooltipItem } from "chart.js";
import dayjs from "dayjs";

import { ChartConfiguration, createTooltipConfiguration, TooltipPluginConfiguration } from "components/ui/Chart";
import { baseChartOptions } from "components/ui/Chart/configurations";
import { showGridOnChartHoverPlugin } from "components/ui/KPI/charts/plugins/show-grid-on-hover.plugin";
import { xAxisLabelsOnChartAreaPlugin } from "components/ui/KPI/charts/plugins/x-axis-labels-on-chart-area.plugin";
import { KPITimeScale } from "components/ui/KPI/kpi.types";
import { formatKPIValue } from "components/ui/KPI/kpi.utils";
import { dateToFormattedYearWeek } from "utils/date.utils";

type Options = {
    getLabel: TooltipPluginConfiguration["callbacks"]["label"];
    /**
     * Biggest value from data values and `suggestedMax`
     */
    suggestedMax?: number;
    /**
     * Smallest value from data values and `suggestedMin`
     */
    suggestedMin?: number;
    timeScale: KPITimeScale;
    useISOWeek?: boolean;
};

// Override for tooltip type receieved from tooltip plugin
type TooltipTitle = Record<"parsed", { x: number; y: number }> & Omit<TooltipItem<any>, "parsed">;

/**
 * _Note_: Suggested values are used for determining axis range, if not provided, the range is determined only by the data
 *
 * __Documentation:__ https://www.chartjs.org/docs/latest/axes/#axis-range-settings
 * __Example:__ https://www.chartjs.org/docs/latest/samples/scales/linear-min-max-suggested.html
 */
export const createKPIChartConfiguration = (options: Options): ChartConfiguration<"line"> => {
    const { getLabel, suggestedMax, suggestedMin, timeScale, useISOWeek } = options;

    return {
        type: "line",
        options: {
            ...baseChartOptions,
            hover: {
                mode: "index",
                intersect: false,
            },
            scales: {
                x: {
                    type: "time",
                    time: {
                        tooltipFormat: "MMMM do, yyyy",
                        displayFormats: {
                            day: "d MMM",
                            month: "MMM yyyy",
                        },
                        isoWeekday: useISOWeek,
                    },
                    ticks: {
                        // Don't show the ticks directly, they will be displayed on the chart area via a plugin
                        display: false,
                        source: "data",
                        callback(tickValue) {
                            if (timeScale === "month") {
                                return dayjs(tickValue).format("MMM");
                            } else {
                                return dayjs(tickValue).format("D MMM");
                            }
                        },
                    },
                    // Hide the axis UI
                    display: false,
                    border: { display: false },
                    grid: { display: false },
                    // Remove the first and last ticks
                    afterBuildTicks: (axis) => {
                        axis.ticks = axis.ticks.slice(1, -1);
                    },
                    // Remove horizontal padding
                    afterFit: (axis) => {
                        axis.height = 0;
                    },
                },
                y: {
                    type: "linear",
                    // Show the axis, but fully show it only on hover
                    display: true,
                    border: { display: false },
                    grid: {
                        color: "transparent",
                    },
                    suggestedMax,
                    // Lowers the suggestedMin by 25% of the chart area height, which leaves
                    // some space for the x axis labels
                    suggestedMin: suggestedMin - (suggestedMax - suggestedMin) * 0.25,
                    ticks: {
                        color: "transparent",
                        font: { size: 10 },
                        align: "end",
                        mirror: true, // Shows the ticks on the inside of the chart area
                        padding: 0, // Removes the padding between the ticks and the chart area
                        count: 5,
                        z: 1, // Makes sure the ticks are displayed on top of the chart area
                        callback: (value) => {
                            // Hides the grid tick labels that would be displayed on the bottom of the chart area
                            const lowerTickBoundary = suggestedMin - (suggestedMax - suggestedMin) * 0.25;
                            if (Number(value) < lowerTickBoundary) return "";

                            return formatKPIValue(value, { numberPrecision: 4 });
                        },
                    },
                    // Remove horizontal padding
                    afterFit: (axis) => {
                        axis.width = 0;
                    },
                },
            },
            layout: {
                autoPadding: false,
                padding: 0,
            },
            plugins: {
                legend: { display: false },
                tooltip: createTooltipConfiguration<{ x: number; y: number; label: string }, "line">({
                    getLabel: getLabel,
                    getTitle: ([firstTooltipItem]) => {
                        if (!firstTooltipItem) return "";

                        const parsedDate = dayjs(firstTooltipItem.parsed.x);

                        if (timeScale === "month") {
                            return parsedDate.format("MMMM YYYY");
                        }

                        const { long: weekLabel } = dateToFormattedYearWeek({
                            date: parsedDate.toISOString(),
                            useISOWeek,
                        });

                        return weekLabel;
                    },
                }),
            },
            events: ["mouseenter", "mouseleave", "mousemove", "mouseout"],
        },
        plugins: [showGridOnChartHoverPlugin, xAxisLabelsOnChartAreaPlugin],
    };
};
