import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from "@reduxjs/toolkit/query/react";
import { Mutex } from "async-mutex";

import { config } from "config";
import { refreshAuth, getStoredAuthData } from "services/auth/auth.service";
import { logout, authenticate } from "store/slices/auth.slice";
import type { AppDispatch } from "store/store";
import { getImpersonationToken } from "utils/impersonation.utils";

export const prepareHeaders = (headers: Headers) => {
    const authData = getStoredAuthData();
    if (authData) {
        headers.set("authorization", `Bearer ${authData.accessToken}`);
    }

    const impersonationToken = getImpersonationToken();
    if (impersonationToken) {
        headers.set("Dsa-Impersonation", `${impersonationToken}`);
    }

    return headers;
};

export const baseQuery = fetchBaseQuery({
    baseUrl: `${config.API_URL}api/`,
    prepareHeaders,
});

const mutex = new Mutex();

const lockMutexAndRevalidate = async (dispatch: AppDispatch) => {
    const release = await mutex.acquire();
    try {
        const authData = await refreshAuth();
        dispatch(authenticate(authData));
    } catch {
        dispatch(logout());
    } finally {
        release();
    }
};

/**
 * Extend fetchBaseQuery which automatically tries to acquire a new accessToken when 401 error is received.
 * with using async-mutex to prevent multiple acquiringTokens attempt when multiple calls fail with 401 Unauthorized errors.
 * source: https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#preventing-multiple-unauthorized-errors
 * @param args
 * @param api
 * @param extraOptions
 */
const baseQueryWithAuthRefresh: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
    args,
    api,
    extraOptions
) => {
    // wait until the mutex is available without locking it
    await mutex.waitForUnlock();
    let result = await baseQuery(args, api, extraOptions);
    if (!result.error || result.error.status !== 401) {
        return result;
    }

    if (!mutex.isLocked()) {
        await lockMutexAndRevalidate(api.dispatch);
    } else {
        await mutex.waitForUnlock();
    }

    result = await baseQuery(args, api, extraOptions);
    return result;
};

export { baseQueryWithAuthRefresh };
