import { useMemo } from "react";
import styled from "styled-components";

import { WidgetFrame } from "components/layout/WidgetFrame";
import { WidgetFrameTableProps } from "components/layout/WidgetFrame/WidgetFrameTable";
import { TableHeadExpandable } from "components/ui/table-final-saviour/Table/TableHead";
import { Row } from "components/ui/table-final-saviour/Table/table.types";

const WidgetFrameTableStyled = styled(WidgetFrame.Table)`
    border-bottom: 1px solid ${(props) => props.theme.color.grayDairyLight};
`;

type Props<T> = WidgetFrameTableProps<T>;

const ExpandedContentSectionTable = <T extends Row>(props: Props<T>) => {
    const components = useMemo(
        () => ({
            ...(props.components ?? {}),
            TableHead: TableHeadExpandable,
        }),
        [props.components]
    );

    return <WidgetFrameTableStyled {...props} components={components} />;
};

export { ExpandedContentSectionTable };
