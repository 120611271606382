import { TableCellExpandAction } from "components/ui/table-final-saviour/Table/TableCell/TableCellExpandAction";
import { TableHeaderCell } from "components/ui/table-final-saviour/Table/TableHeaderCell";
import { TableContextExpandable } from "components/ui/table-final-saviour/Table/table-expandable.types";
import { Column, Row, TableCellBaseProps } from "components/ui/table-final-saviour/Table/table.types";

export const createActionExpandColumn = <T extends Row>(): Column<
    T,
    TableCellBaseProps<T, TableContextExpandable<T>>
> => {
    return {
        _type: "action-expand",
        components: {
            TableHeaderCell: (props) => <TableHeaderCell {...props} width={64} />,
            TableCell: (props) => {
                const { context, ...otherProps } = props;

                if (!context) {
                    throw new Error("Missing context in TableCellExpandAction");
                }

                const { getIsExpandedRow, toggleExpandedRow } = context;

                return (
                    <TableCellExpandAction
                        {...otherProps}
                        isExpanded={getIsExpandedRow(props.row)}
                        onExpand={() => toggleExpandedRow(props.row)}
                    />
                );
            },
        },
    };
};
