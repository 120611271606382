// @ts-strict-ignore
import { ChartConfiguration } from "components/ui/Chart/chart.types";
import { baseChartOptions } from "components/ui/Chart/configurations/chart-configurations/base.chart-configuration";
import { createLegendConfiguration } from "components/ui/Chart/configurations/plugins/legend.plugin-configuration";
import { createTooltipConfiguration } from "components/ui/Chart/configurations/plugins/tooltip.plugin-configuration";

export const barChartConfiguration: ChartConfiguration<"bar"> = {
    type: "bar",
    options: {
        ...baseChartOptions,
        elements: {
            bar: {
                borderRadius: 4,
            },
        },
        scales: {},
        plugins: {
            legend: createLegendConfiguration(),
            tooltip: createTooltipConfiguration({
                intersect: true,
            }),
        },
    } as ChartConfiguration<"bar">["options"],
};

type CreateBarChartConfigurationOptions = {
    scales: ChartConfiguration<"bar">["options"]["scales"];
    plugins?: ChartConfiguration<"bar">["options"]["plugins"];
    onClick?: ChartConfiguration<"bar">["options"]["onClick"];
};

export const createBarChartConfiguration = (options: CreateBarChartConfigurationOptions): ChartConfiguration<"bar"> => {
    return {
        ...barChartConfiguration,
        options: {
            ...barChartConfiguration.options,
            scales: options.scales,
            plugins: {
                ...barChartConfiguration.options.plugins,
                ...options.plugins,
            },
            onClick: options.onClick,
        },
    };
};
