import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type State = {
    confluencePageId: number | null;
};

const initialState: State = {
    confluencePageId: null,
};

const slice = createSlice({
    name: "documentation",
    initialState,
    reducers: {
        setConfluencePageId: (state, action: PayloadAction<number>) => {
            state.confluencePageId = action.payload;
        },
        clearConfluencePageId: (state) => {
            state.confluencePageId = null;
        },
    },
});

export const { setConfluencePageId, clearConfluencePageId } = slice.actions;
export const documentationReducer = slice.reducer;
