import styled from "styled-components";

import { confluenceCssOverrides } from "features/documentation/DocumentationWindow/documentation-window.utils";

export const ConfluencePageViewContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ConfluencePageViewHeader = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    margin-bottom: 1rem;
`;

export const ConfluencePageViewHeaderTitle = styled.div`
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.25rem;
`;

export const ConfluencePageViewHeaderLastUpdated = styled.div`
    font-size: 0.875rem;

    color: var(--wds-color-text-lighter);
`;

export const ConfluencePageViewContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;

    font-size: 1rem;

    ${confluenceCssOverrides};
`;
