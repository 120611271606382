import { z } from "zod";

import { Route } from "routes/routes.types";

const createRoutes = <const T extends Record<string, Route>>(routes: T) => routes;

export const routes = createRoutes({
    abnormalPreventiveMaintenanceTimes: {
        path: "/performance/abnormal-preventive-maintenance-times",
    },
    administration: {
        path: "/administration/*",
    },
    agreements: {
        path: "/agreements",
    },
    attentionCustomers: {
        path: "/attention-customers",
    },
    averageRepairTimes: {
        path: "/performance/avg-repair-times",
    },
    connectionPerformance: {
        path: "/engineering/connection-performance",
    },
    criticalAlarmsAnalysis: {
        path: "/critical-alarms-analysis/*",
        subRoutes: {
            astronaut: {
                path: "",
            },
            vector: {
                path: "vector",
            },
        },
    },
    customerDetail: {
        path: "/customers/:customerNumber/*",
        paramsSchema: z.object({
            customerNumber: z.string(),
        }),
        searchParamsSchema: z.object({
            farmLicenseKey: z.string().optional(),
            deviceSerialNumber: z.string().optional(),
            reportId: z.string().optional(),
        }),
        subRoutes: {
            customerInfo: {
                path: "",
            },
            milking: {
                path: "milking",
                subRoutes: {
                    overview: {
                        path: "",
                    },
                    criticalAlarms: {
                        path: "critical-alarms",
                    },
                    visitBehaviour: {
                        path: "visit-behaviour",
                    },
                    pretreatment: {
                        path: "pretreatment",
                    },
                    connectionPerformance: {
                        path: "connection-performance",
                    },
                    duringMilking: {
                        path: "during-milking",
                    },
                    takeOffReasons: {
                        path: "take-off-reasons",
                    },
                    cleaningPerformance: {
                        path: "cleaning-performance",
                    },
                },
            },
            feeding: {
                path: "feeding",
                subRoutes: {
                    overview: {
                        path: "",
                    },
                    alarmAnalysis: {
                        path: "alarm-analysis",
                    },
                },
            },
            consumables: {
                path: "consumables",
            },
            careCheck: {
                path: "care-check",
                subRoutes: {
                    report: {
                        path: "report",
                    },
                    edit: {
                        path: "edit",
                        paramsSchema: z.object({
                            customerNumber: z.string(),
                            reportId: z.string(),
                        }),
                    },
                    detail: {
                        path: "detail",
                        paramsSchema: z.object({
                            customerNumber: z.string(),
                            reportId: z.string(),
                        }),
                    },
                },
            },
            macAddress: {
                path: "mac-address",
            },
        },
    },
    customerFarmCompressors: {
        path: "/customers/:customerNumber/farms/:farmLicenseKey/compressors",
        paramsSchema: z.object({
            customerNumber: z.string(),
            farmLicenseKey: z.string(),
        }),
    },
    customerDeviceDetail: {
        path: "/customers/:customerNumber/devices/:deviceType/:deviceSerialNumber",
        paramsSchema: z.object({
            customerNumber: z.string(),
            deviceType: z.enum(["astronauts", "compressors", "discovery", "juno", "vector", "other"]),
            deviceSerialNumber: z.string(),
        }),
        searchParamsSchema: z.object({
            insightId: z.string().optional(),
        }),
    },
    customerDeviceReport: {
        path: "/customers/:customerNumber/devices/astronauts/:deviceSerialNumber/report",
        paramsSchema: z.object({
            customerNumber: z.string(),
            deviceSerialNumber: z.string(),
        }),
        searchParamsSchema: z.object({
            farmLicenseKey: z.string(),
        }),
    },
    customers: {
        path: "/customers",
    },
    dashboard: {
        path: "/dashboard",
    },
    devices: {
        path: "/devices",
    },
    farmPerformance: {
        path: "/farms/:farmLicenseKey/performance",
        paramsSchema: z.object({
            farmLicenseKey: z.string(),
        }),
    },
    farmScan: {
        path: "/farms/farm-scan",
        searchParamsSchema: z.object({
            farmLicenseKey: z.string().optional(),
            beta: z.coerce.boolean().optional(),
        }),
    },
    horizonUsage: {
        path: "/horizon-usage",
    },
    longRepairs: {
        path: "/performance/long-repairs",
    },
    mandatoryUpdates: {
        path: "/performance/mandatory-updates",
    },
    manHoursHigh: {
        path: "/performance/man-hours-high",
    },
    mfpBimodality: {
        path: "/engineering/mfp-bimodality",
    },
    mfpPlotter: {
        path: "/engineering/mfp-plotter",
        searchParamsSchema: z.object({
            farmLicenseKey: z.string().optional(),
            date: z.string().optional(),
            visitId: z.coerce.number().optional(),
        }),
    },
    partDetail: {
        path: "/parts/:partNumber",
        paramsSchema: z.object({
            partNumber: z.coerce.string(),
        }),
    },
    parts: {
        path: "/parts",
    },
    productiveTime: {
        path: "/performance/productive-time",
    },
    repairsPerAstronaut: {
        path: "/performance/repairs-per-astronaut",
    },
    repeatVisitCases: {
        path: "/performance/repeat-visit-cases",
    },
    preventiveMaintenanceOverdue: {
        path: "/performance/preventive-maintenance-overdue",
    },
    profitability: {
        path: "/profitability",
    },
    profitabilityCriticalAgreements: {
        path: "/profitability/critical-agreements",
    },
    serviceAreaSupport: {
        path: "/service-area-support",
    },
    strategicOverview: {
        path: "/strategic-overview",
    },
    TDSLinerAdvice: {
        path: "/engineering/tds-liner-advice",
    },
    technicianDetail: {
        path: "/technicians/:technicianCode",
        paramsSchema: z.object({
            technicianCode: z.string(),
        }),
    },
    technicianDetailAbnormalPMTimes: {
        path: "/technicians/:technicianCode/abnormal-pm-times",
        paramsSchema: z.object({
            technicianCode: z.string(),
        }),
    },
    technicianDetailLongRepairs: {
        path: "/technicians/:technicianCode/long-repairs",
        paramsSchema: z.object({
            technicianCode: z.string(),
        }),
    },
    technicianDetailRepeatVisitRate: {
        path: "/technicians/:technicianCode/repeat-visit-rate",
        paramsSchema: z.object({
            technicianCode: z.string(),
        }),
    },
    technicians: {
        path: "/technicians",
    },
});
export type Routes = typeof routes;

export const fmsReportRoute: Route = {
    path: "/farm-scan-report/:token",
    paramsSchema: z.object({
        token: z.string(),
    }),
};
