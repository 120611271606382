import styled from "styled-components";

import { TableCell } from "components/ui/table-final-saviour/Table/TableCell";
import { TableHeaderCell } from "components/ui/table-final-saviour/Table/TableHeaderCell";
import { Column, Row, TableCellBaseProps } from "components/ui/table-final-saviour/Table/table.types";

const FlexContainer = styled.div`
    display: flex;
    justify-content: center;
`;

type Options<T extends Row> = {
    title?: string;
    width?: number;
    getChip: (props: TableCellBaseProps<T>) => React.ReactNode;
};

export const createChipColumn = <T extends Row>(options: Options<T>): Column<T> => {
    const { getChip } = options;

    return {
        _type: "chips",
        components: {
            TableHeaderCell: (props) => (
                <TableHeaderCell {...props} width={options.width} align={"center"}>
                    {options.title}
                </TableHeaderCell>
            ),
            TableCell: (props) => {
                return (
                    <TableCell {...props} width={options.width}>
                        <FlexContainer>{getChip(props)}</FlexContainer>
                    </TableCell>
                );
            },
        },
    };
};
