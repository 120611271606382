// @ts-strict-ignore
import { SearchResultListItem } from "components/layout/Navbar/ApplicationSearch/SearchBar/SearchResultMenu/SearchResultListItem";
import { TechnicianRow } from "components/layout/Navbar/ApplicationSearch/application-search.types";
import { routes } from "routes";
import { generatePath } from "routes/routes.utils";

type Props = {
    data: TechnicianRow;
    onClick: () => void;
};

export const TechnicianListItem = (props: Props) => {
    const { data } = props;

    return (
        <SearchResultListItem
            label={data.Name ?? "Unknown technician"}
            chip={data.TechnicianCode}
            title={"Technician"}
            url={generatePath(routes.technicianDetail, {
                params: { technicianCode: data.TechnicianCode },
            })}
            icon={"lely-icon-person"}
            onClick={props.onClick}
        />
    );
};
