// @ts-strict-ignore
import { AuthResponseError } from "services/auth/auth.types";

export class FetchError extends Error {
    constructor() {
        super("Error while fetching data");
    }
}

export class JSONParseError extends Error {
    constructor() {
        super("Data not in a valid JSON shape");
    }
}

export class ValidationError extends Error {
    constructor() {
        super("Data not in a valid format");
    }
}

export class AuthSessionExpiredError extends Error {
    constructor() {
        super("Application session expired");
    }
}

export class AuthError extends Error {
    data: AuthResponseError;

    constructor(data?: AuthResponseError) {
        super("Authentication error: " + (data?.error_description ?? "Unknown error"));
        this.data = data;
    }
}
