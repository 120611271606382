// @ts-strict-ignore
import { useCallback, useMemo, useState } from "react";

import { BooleanFilter } from "components/ui/BooleanFilters/boolean-filters.types";

type Options<TData, TKey extends string = string> = {
    filters: BooleanFilter<TData, TKey>[];
    exclusive?: boolean;
    defaultActiveFilters?: TKey[];
};

export const useBooleanFilters = <TData, TKey extends string = string>(options: Options<TData, TKey>) => {
    const { exclusive, defaultActiveFilters } = options;
    const [activeFilters, setActiveFilters] = useState<TKey[]>(defaultActiveFilters ?? []);

    const filters = useMemo(() => {
        if (exclusive) {
            return [
                {
                    key: "all",
                    title: "All",
                    condition: () => true,
                },
                ...options.filters,
            ];
        } else {
            return options.filters;
        }
    }, [options.filters, exclusive]);

    const setActiveFilter = useCallback(
        (filter: TKey) => {
            if (exclusive === true) {
                setActiveFilters([filter]);
            } else {
                setActiveFilters((prev) => {
                    if (prev.includes(filter)) {
                        return prev.filter((f) => f !== filter);
                    } else {
                        return [...prev, filter];
                    }
                });
            }
        },
        [exclusive, setActiveFilters]
    );

    const applyFilters = useCallback(
        (data: TData[]) => {
            return data.filter((item) => {
                const activeFiltersConditions = activeFilters.map(
                    (filter) => filters.find((f) => f.key === filter).condition
                );
                return activeFiltersConditions.every((condition) => condition(item));
            });
        },
        [filters, activeFilters]
    );

    return { filters, activeFilters, setActiveFilter, applyFilters };
};
