// @ts-strict-ignore
import type { ComponentType, ReactNode } from "react";

import { TableCell as TableCellDefault, TableCellProps } from "components/ui/table-final-saviour/Table/TableCell";
import {
    TableHeaderCellSortable as TableHeaderCellDefault,
    TableHeaderCellSortableProps,
} from "components/ui/table-final-saviour/Table/TableHeaderCell";
import { SortConfig, SortOrder } from "components/ui/table-final-saviour/Table/TableHeaderCell/TableHeaderCellSortable";
import { TableHeaderCellSortableWithTooltip } from "components/ui/table-final-saviour/Table/TableHeaderCell/TableHeaderCellSortable/TableHeaderCellSortableWithTooltip";
import { DataColumnDataType } from "components/ui/table-final-saviour/Table/columns/table-columns.types";
import { useSort } from "components/ui/table-final-saviour/Table/hooks/useSort";
import { Column, Row } from "components/ui/table-final-saviour/Table/table.types";
import { readCellValue } from "components/ui/table-final-saviour/Table/table.utils";
import { splitAndCapitalize } from "utils/string.utils";

type GetCellValueOptions<T extends Row> = {
    column: DataSortableColumn<T>;
    row: T;
    value: T[keyof T];
};

type UseSortControls<T extends Row> = ReturnType<typeof useSort<T>>["sortControls"];
export interface DataSortableColumn<T extends Row>
    extends Column<T>,
        Pick<TableCellProps<T>, "align" | "fontStyle" | "width">,
        UseSortControls<T> {
    _type: "data_sortable";
    dataKey: keyof T;
    dataType: DataColumnDataType;
    title?: string;
    titleTooltip?: ReactNode;
    getCellValue?: (options: GetCellValueOptions<T>) => React.ReactNode;
    components?: {
        TableCell?: ComponentType<TableCellProps<T>>;
        TableHeaderCell?: ComponentType<TableHeaderCellSortableProps<T>>;
    };
}

const getSortOrder = (key: keyof Row, options: SortConfig<any>): SortOrder => {
    if (options.key !== key) return "none";
    return options.order;
};

export const createDataSortableColumn = <T extends Row>(
    options: Omit<DataSortableColumn<T>, "_type">
): DataSortableColumn<T> => {
    const { dataKey, dataType, titleTooltip, components } = options;

    const TableCell: ComponentType<TableCellProps<T>> = components?.TableCell ?? TableCellDefault;

    const getCellValue = options?.getCellValue ?? readCellValue;

    const sortOrder = getSortOrder(dataKey, options.sortConfig);
    const handleSetSortByKey = () => options.setSortByKey({ dataKey, dataType });

    return {
        _type: "data_sortable",
        ...options,
        components: {
            TableCell: (props) => (
                <TableCell align={options?.align} width={options?.width} {...props}>
                    {getCellValue({
                        column: props.column as DataSortableColumn<T>,
                        row: props.row,
                        value: props.row[dataKey],
                    })}
                </TableCell>
            ),
            TableHeaderCell: (props) => {
                const tableHeaderCellLabel = options?.title ?? splitAndCapitalize(String(dataKey));
                const customHeaderCell = !!components?.TableHeaderCell;

                if (!customHeaderCell && titleTooltip) {
                    return (
                        <TableHeaderCellSortableWithTooltip
                            align={options?.align}
                            width={options?.width}
                            tooltip={titleTooltip}
                            sortOrder={sortOrder}
                            onClick={handleSetSortByKey}
                            {...props}
                        >
                            {tableHeaderCellLabel}
                        </TableHeaderCellSortableWithTooltip>
                    );
                }

                const TableHeaderCell: ComponentType<TableHeaderCellSortableProps<T>> =
                    components?.TableHeaderCell ?? TableHeaderCellDefault;

                return (
                    <TableHeaderCell
                        align={options?.align}
                        width={options?.width}
                        sortOrder={sortOrder}
                        onClick={handleSetSortByKey}
                        {...props}
                    >
                        {tableHeaderCellLabel}
                    </TableHeaderCell>
                );
            },
        },
    };
};
