import { Alert } from "@datamole/wds-component-alert";

type Props = {
    confluencePageId: number;
};

const DocumentationWindowContentError = (props: Props) => {
    const { confluencePageId } = props;

    return (
        <Alert severity={"warning"}>
            <Alert.Icon />
            <Alert.Title>Page not found (ID: {confluencePageId})</Alert.Title>
        </Alert>
    );
};

export { DocumentationWindowContentError };
