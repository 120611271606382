import { apiJS as baseApi } from "store/api";

export type ConfluencePage = {
    id: number;
    title: string;
    status: string;
    content: string;
    updatedAt: string;
};

const TAG_PAGE = "TAG_PAGE";

const api = baseApi
    .enhanceEndpoints({
        addTagTypes: [TAG_PAGE],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getConfluencePage: builder.query<ConfluencePage, { pageId: number }>({
                query: ({ pageId }) => ({
                    url: `confluence/page/${pageId}`,
                    method: "GET",
                }),
            }),
        }),
    });

export const { useGetConfluencePageQuery } = api;
