// @ts-strict-ignore
import type { DataWrapper } from "deprecated/data-wrapper";
import { filter } from "deprecated/data-wrapper";

export function search(
    searchTerm: string,
    data: DataWrapper,
    fmt?: (val: unknown) => string,
    fields?: Array<string>,
    ignoreFields: Array<string> = [],
    strict = false
): DataWrapper {
    if (typeof searchTerm !== "string" || searchTerm.length === 0) {
        return data;
    } else {
        const searchTermLower = searchTerm.toLocaleLowerCase();
        return filter(data, (row) => {
            for (let i = 0; i < fields.length; i++) {
                if (ignoreFields.indexOf(fields[i]) === -1) {
                    const value =
                        typeof fmt !== "undefined"
                            ? fmt(row[fields[i]]).toLocaleLowerCase()
                            : String(row[fields[i]]).toLocaleLowerCase();

                    if (strict) {
                        if (value === searchTermLower) {
                            return true;
                        }
                    } else {
                        if (value.indexOf(searchTermLower) > -1) {
                            return true;
                        }
                    }
                }
            }

            return false;
        });
    }
}
export function searchAndMerge(
    searchTerms: Array<string>,
    data: DataWrapper,
    fmt?: (val: unknown) => string,
    fields?: Array<string>,
    ignoreFields: Array<string> = [],
    strict = false
): DataWrapper {
    if (searchTerms.length === 0) {
        return data;
    } else {
        return filter(data, (row) => {
            let res = false;

            for (let i = 0; i < fields.length; i++) {
                if (ignoreFields.indexOf(fields[i]) === -1) {
                    const value =
                        typeof fmt !== "undefined"
                            ? fmt(row[fields[i]]).toLocaleLowerCase()
                            : String(row[fields[i]]).toLocaleLowerCase();
                    searchTerms.map((term) => {
                        if (term === "Empty" && (row[fields[i]] === null || row[fields[i]] === "")) {
                            res = true;
                        }

                        if (strict) {
                            if (value === term.toLocaleLowerCase()) {
                                res = true;
                            }
                        } else {
                            if (value.indexOf(term.toLocaleLowerCase()) > -1) {
                                res = true;
                            }
                        }
                    });
                }
            }

            return res;
        });
    }
}

// TODO: move to data wrapper in UI repo
export function skip(data: DataWrapper, amount: number): DataWrapper {
    return filter(data, (row, i) => i >= amount);
}
