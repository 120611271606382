// @ts-strict-ignore
import { ChartConfiguration } from "components/ui/Chart/chart.types";
import { theme } from "styles/theme";

export const createRadialLinearAxis = (options?: {
    min?: number;
    max?: number;
}): ChartConfiguration<"radar">["options"]["scales"]["r"] => ({
    ticks: {
        display: false,
        count: 2,
        precision: 2,
        backdropColor: theme.color.white + "00",
    },
    pointLabels: {
        display: true,
        padding: 8,
        font: {
            size: 12,
        },
    },
    max: options?.max,
    min: options?.min ?? 0,
});
