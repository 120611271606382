// @ts-strict-ignore
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useSendUserEvent } from "hooks/user-event/useSendUserEvent";
import { getRouteFromPath } from "hooks/user-event/user-event.utils";
import { pageAtRouteMap } from "pages";

export const useSendLocationChangeUserEvent = () => {
    const { pathname } = useLocation();
    const sendUserEvent = useSendUserEvent();

    useEffect(() => {
        let pageTitle = document.title.split(" | ")[0];

        // Try to find the page route from the pathname and get the page title
        const route = getRouteFromPath(pathname);
        if (route) {
            const [routeId] = route;
            const page = pageAtRouteMap[routeId];
            if (page) {
                pageTitle = page.title;
            }
        }

        sendUserEvent({
            type: "location-change",
            value: pageTitle,
        });

        // TODO: add cleanup function
    }, [pathname, sendUserEvent]);
};
