// @ts-strict-ignore
import { WidgetFrame } from "components/layout/WidgetFrame";
import { WidgetWithQueryParameters } from "components/layout/WidgetFrame/widget.types";
import { type Regions, KPI, getThresholdsFromRegions, getActiveRegion, toKPIChartDataPoints } from "components/ui/KPI";
import { useDataSource } from "hooks/useDataSource";
import { routes } from "routes";
import { generatePath } from "routes/routes.utils";

type Props = WidgetWithQueryParameters<"dateFrom" | "dateTo">;

const RepairsPerAstronautKPIWidget = (props: Props) => {
    const { queryParameters } = props;
    const title = "Repairs per Astronaut";
    const subtitle = "12 month averages";

    const chartDataSource = useDataSource("Dashboard_RepairsPerAstronautPast12Months_KPI_Chart", queryParameters);
    const { data, lastDataPoint } = toKPIChartDataPoints(chartDataSource?.data ?? []);

    const regions = [
        { type: "region", color: "red" },
        { type: "threshold", value: 12, regionIfEqual: "lower" },
        { type: "region", color: "pink" },
        { type: "threshold", value: 10, regionIfEqual: "lower" },
        { type: "region", color: "orange" },
        { type: "threshold", value: 8, regionIfEqual: "lower" },
        { type: "region", color: "green" },
        { type: "threshold", value: 5, regionIfEqual: "upper" },
        { type: "region", color: "red" },
    ] satisfies Regions;
    const thresholds = getThresholdsFromRegions({ regions, lastDataPoint });
    const activeRegion = getActiveRegion({ regions, lastDataPoint });
    const color = activeRegion?.color;

    return (
        <>
            <WidgetFrame.Title
                title={title}
                subtitle={subtitle}
                icon={"lely-icon-astronaut"}
                size={"sm"}
                link={generatePath(routes.repairsPerAstronaut)}
            >
                <WidgetFrame.Title.Information lastImportType={"middleware"} pageId={207487095}>
                    Every data-point represents the average of the last 12 months from that month.
                </WidgetFrame.Title.Information>
            </WidgetFrame.Title>
            <WidgetFrame.KPI dependencies={[chartDataSource]}>
                <KPI.LastValue title={"Last Selected Full 12 Months"} value={lastDataPoint?.label} color={color} />
                <KPI.Chart data={data} color={color} thresholds={thresholds} />
            </WidgetFrame.KPI>
        </>
    );
};

export { RepairsPerAstronautKPIWidget };
