// @ts-strict-ignore
import { useCallback, useState } from "react";

import { DataColumnDataType } from "components/ui/table-final-saviour/Table/columns/table-columns.types";
import { Row } from "components/ui/table-final-saviour/Table/table.types";
import { compareFnMap } from "components/ui/table-final-saviour/Table/table.utils";

export type SortOrder = "none" | "asc" | "desc";
export type SortConfig<T extends Row> = {
    key: keyof T | null;
    order: SortOrder;
    dataType: DataColumnDataType | null;
};

const defaultSortConfig: SortConfig<any> = { key: null, order: "none", dataType: null };

type SortOrderWithoutNone = Exclude<SortOrder, "none">;
type SortOrderPriority = [SortOrderWithoutNone, SortOrderWithoutNone];
const defaultOrderPriority: SortOrderPriority = ["asc", "desc"];
const dataTypeCustomOrderPriorityMap: Partial<Record<DataColumnDataType, SortOrderPriority>> = {
    number: ["desc", "asc"],
    date: ["desc", "asc"],
};

type UseSortOptions<T extends Row> = {
    initialSortConfig?: SortConfig<T>;
};

export const useSort = <T extends Row>(options?: UseSortOptions<T>) => {
    const { initialSortConfig } = options ?? {};

    const [sortConfig, setSortConfig] = useState<SortConfig<T>>(initialSortConfig ?? defaultSortConfig);

    const setSortByKey = useCallback(({ dataKey, dataType }: { dataKey: keyof T; dataType: DataColumnDataType }) => {
        setSortConfig((prevSortConfig) => {
            const prevSortKey = prevSortConfig.key;
            const prevSortOrder = prevSortConfig.order;

            const orderPriority = dataTypeCustomOrderPriorityMap[dataType] ?? defaultOrderPriority;
            const [primarySortOrder, reversedSortOrder] = orderPriority;

            if (prevSortKey !== dataKey) {
                return { key: dataKey, order: primarySortOrder, dataType };
            } else if (prevSortOrder === primarySortOrder) {
                return { key: dataKey, order: reversedSortOrder, dataType };
            } else {
                return defaultSortConfig;
            }
        });
    }, []);

    const applySort = useCallback(
        (data: T[]) => {
            const { key, order, dataType } = sortConfig;

            if (key === null) return data;

            const compareFn = dataType ? compareFnMap[dataType] : undefined;
            if (!compareFn) return data;

            const sortedData = [...data].sort(compareFn(sortConfig.key, order === "desc"));
            return sortedData;
        },
        [sortConfig]
    );

    return {
        sortControls: { sortConfig, setSortByKey },
        applySort,
    };
};
