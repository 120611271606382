import { ReactNode, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

import { ELEMENT_SCROLL_CONTENT_ID } from "components/layout/layout.consts";

const ContentStyled = styled.main`
    grid-area: content;
`;

type Props = {
    children: ReactNode;
};

function Content(props: Props) {
    const location = useLocation();
    const sectionRef = useRef<HTMLElement | null>(null);

    useEffect(() => {
        if (sectionRef.current === null) return;

        sectionRef.current.scrollTo(0, 0);
    }, [location.pathname, sectionRef]);

    return (
        <ContentStyled ref={sectionRef} id={ELEMENT_SCROLL_CONTENT_ID}>
            {props.children}
        </ContentStyled>
    );
}

export { Content };
