// @ts-strict-ignore
import { ScriptableContext } from "chart.js";

import { ChartData } from "components/ui/Chart/chart.types";

/**
 * @param options.fill Options: solid/gradient/(relative dataset/absolute dataset) - documentation: https://www.chartjs.org/docs/latest/charts/area.html
 */
export const createLineChartDataset = <T = unknown>(options: {
    label?: string;
    data: T;
    color: string;
    hidePoint?: boolean;
    yAxisID?: string;
    xAxisID?: string;
    showLine?: boolean;
    fill?: "solid" | "gradient" | `${"+" | "-" | ""}${number}` | number;
}): ChartData<"line", T>["datasets"][number] => {
    const customFillRegex = /^[+-]?[0-9]*$/; // matches absolute & relative dataset indexes
    // if fill matches this regex, pass fill property to the dataset, otherwise convert fill property to boolean
    const isCustomFill = typeof options.fill === "number" || customFillRegex.test(options.fill);
    const fill = isCustomFill ? options.fill : !!options.fill;

    return {
        type: "line" as const,
        yAxisID: options.yAxisID,
        xAxisID: options.xAxisID,
        label: options.label,
        data: options.data,
        borderColor: options.color,
        borderWidth: 2,
        pointRadius: options.hidePoint ? 0 : 4,
        pointBackgroundColor: options.color,
        pointBorderWidth: 2,
        pointBorderColor: "white",
        pointHoverRadius: options.hidePoint ? 3 : 4,
        pointHoverBackgroundColor: options.color,
        pointHoverBorderColor: options.color,
        showLine: options.showLine,
        fill,
        backgroundColor: (context: ScriptableContext<"line">) => {
            const shouldHaveSolidBackground = options.fill !== "gradient" || !context.chart?.chartArea;
            if (shouldHaveSolidBackground) return options.color + "33";

            const ctx = context.chart.ctx;
            const chartArea = context.chart.chartArea;
            const gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
            gradient.addColorStop(0, options.color + "ff");
            gradient.addColorStop(1, options.color + "00");
            return gradient;
        },
    };
};
