import { CSSProperties, ReactNode } from "react";
import styled from "styled-components";

import { Loader } from "components/ui/Loader/Loader";

const LoaderContentStyled = styled.div`
    position: relative;

    width: 100%;
    height: 100%;
    min-height: 2rem;

    border-radius: 4px;
`;

const LoaderCover = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;

    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 4px;

    backdrop-filter: blur(2px);
`;

type Props = {
    /**
     * Shows empty content when set to `true`
     */
    isLoading?: boolean;
    /**
     * Shows blurred out content when set to `true`
     */
    isFetching?: boolean;
    /**
     * Displayed wrapped behind a loader or returned back based on loading props
     */
    children: ReactNode;
    className?: string;
    style?: CSSProperties;
};

const LoaderContent = (props: Props) => {
    const { isLoading = false, isFetching = false, children } = props;

    if (isLoading === false && isFetching === false) return <>{children}</>;

    return (
        <LoaderContentStyled className={props.className} style={props.style}>
            {isLoading ? null : children}
            <LoaderCover>
                <Loader size={"24px"} />
            </LoaderCover>
        </LoaderContentStyled>
    );
};

export { LoaderContent };
