// @ts-strict-ignore
import { ChartConfiguration } from "components/ui/Chart/chart.types";
import { baseChartOptions } from "components/ui/Chart/configurations/chart-configurations/base.chart-configuration";
import { createLegendConfiguration } from "components/ui/Chart/configurations/plugins/legend.plugin-configuration";
import { createTooltipConfiguration } from "components/ui/Chart/configurations/plugins/tooltip.plugin-configuration";
import { createVerticalLinePlugin } from "components/ui/Chart/plugins";

export const lineChartConfiguration: ChartConfiguration<"line"> = {
    type: "line",
    options: {
        ...baseChartOptions,
        elements: {
            point: { radius: 0 },
            line: {
                borderWidth: 1,
            },
            bar: {
                borderRadius: 4,
            },
        },
        hover: {
            mode: "index",
            intersect: false,
        },
        scales: {},
        plugins: {
            legend: createLegendConfiguration(),
            tooltip: createTooltipConfiguration(),
        },
    } as ChartConfiguration<"line">["options"],
    plugins: [createVerticalLinePlugin()],
};

type Options = {
    scales: ChartConfiguration<"line">["options"]["scales"];
    plugins?: ChartConfiguration<"line">["options"]["plugins"];
};
export const createLineChartConfiguration = (options: Options): ChartConfiguration<"line"> => {
    return {
        ...lineChartConfiguration,
        options: {
            ...lineChartConfiguration.options,
            scales: options.scales,
            plugins: {
                ...lineChartConfiguration.options.plugins,
                ...options.plugins,
            },
        },
    };
};
