// @ts-strict-ignore
import { useContext, useState } from "react";

import { TableContext } from "components/components-deprecated/tables/components/BasicTable";
import ExpandableRowWithInnerLayout from "components/components-deprecated/tables/components/ExpandableRowWithInnerLayout";
import Headers from "components/components-deprecated/tables/components/Headers";
import StandardRow from "components/components-deprecated/tables/components/StandardRow";
import { getCellContent, getHeadersForLevel } from "components/components-deprecated/tables/tables.helper";
import { ExpandableRowProps } from "components/components-deprecated/tables/tables.interfaces";
import { render } from "components/components-deprecated/tables/widget-hooks.helper";
import { Flex, Icon } from "components/ui-deprecated";
import { map } from "deprecated/data-wrapper";
import { processData, toDataWrapper } from "deprecated/data-wrapper/data.utils";
import { toDataSourceParameters, useLazyDataSource } from "hooks/useDataSource";
import { DataSourceParameter } from "store/services/data-source.service";

const ExpandableRow: React.FC<ExpandableRowProps> = ({
    expandableQueryParameters,
    row,
    level,
    headers,
    doubleExpandable = false,
    widerColumns,
}) => {
    const [queryData, setQueryData] = useState();
    const [expanded, setExpanded] = useState(false);
    const {
        hiddenColumns,
        formatter,
        fieldsToLinks,
        customColumnTypes,
        combineColumns,
        setContextFromColumns,
        expandedInnerTableLayout,
        addHeaders,
    } = useContext(TableContext);

    const level1QueryParameters = (Object.entries(expandableQueryParameters?.level1?.parameters ?? {}).map(
        ([name, dataKey]) => ({
            name,
            value: row[dataKey],
        })
    ) ?? []) as DataSourceParameter[];
    const level2QueryParameters = (Object.entries(expandableQueryParameters?.level2?.parameters ?? {}).map(
        ([name, dataKey]) => ({
            name,
            value: row[dataKey],
        })
    ) ?? []) as DataSourceParameter[];
    const [trigger, { data: innerData, isFetching, isError }] = useLazyDataSource(
        expandableQueryParameters?.level1?.dataSource
    );

    const handleExpandRow = () => {
        trigger([
            ...toDataSourceParameters(expandableQueryParameters?.level0),
            ...level1QueryParameters,
            ...level2QueryParameters,
        ]);

        setQueryData(queryData);
        setExpanded(!expanded);
    };

    const innerDataProcessed = processData(innerData, expandableQueryParameters?.level1?.processDataConfig ?? {});
    const innerDataWrapper = toDataWrapper(innerDataProcessed);

    return (
        <div className={"expandable-row-container"}>
            <Flex className={"basic-table-row expandable-row"} onClick={handleExpandRow}>
                <Flex className={"toggle-button-wrapper"}>
                    <div className={"toggle-button"}>
                        <Icon icon={expanded ? "lely-icon-caret-up" : "lely-icon-caret-down"} />
                    </div>
                </Flex>
                {headers.map((field) => (
                    <Flex className={`basic-table-cell ${widerColumns.includes(field) ? "wider" : ""}`} key={field}>
                        {(() => {
                            return getCellContent(
                                row,
                                field,
                                formatter,
                                fieldsToLinks,
                                customColumnTypes,
                                combineColumns,
                                setContextFromColumns
                            );
                        })()}
                    </Flex>
                ))}
            </Flex>
            {expanded &&
                render(
                    <div className={"row-expansion"}>
                        <Headers level={1} data={innerDataWrapper} grouping={false} />
                        <div>
                            {map(innerDataWrapper, (item) => {
                                return doubleExpandable ? (
                                    expandedInnerTableLayout ? (
                                        <ExpandableRowWithInnerLayout
                                            expandableQueryParameters={expandableQueryParameters}
                                            row={item}
                                            level={1}
                                            headers={getHeadersForLevel(
                                                innerDataWrapper,
                                                hiddenColumns,
                                                level + 1,
                                                false,
                                                addHeaders
                                            )}
                                            widerColumns={widerColumns}
                                            innerTableLayout={expandedInnerTableLayout}
                                        />
                                    ) : (
                                        <ExpandableRow
                                            key={JSON.stringify(item)}
                                            level={1}
                                            expandableQueryParameters={expandableQueryParameters}
                                            row={item}
                                            headers={getHeadersForLevel(
                                                innerDataWrapper,
                                                hiddenColumns,
                                                level + 1,
                                                false,
                                                addHeaders
                                            )}
                                            doubleExpandable={false}
                                            widerColumns={widerColumns}
                                        />
                                    )
                                ) : (
                                    <StandardRow
                                        key={JSON.stringify(item)}
                                        row={item}
                                        headers={getHeadersForLevel(
                                            innerDataWrapper,
                                            hiddenColumns,
                                            level + 1,
                                            false,
                                            addHeaders
                                        )}
                                        formatter={formatter}
                                        fieldsToLinks={fieldsToLinks}
                                        customColumnTypes={customColumnTypes}
                                        setContextFromColumns={setContextFromColumns}
                                        widerColumns={widerColumns}
                                    />
                                );
                            })}
                        </div>
                    </div>,
                    isError,
                    true,
                    isFetching
                )}
        </div>
    );
};

export default ExpandableRow;
