import { ReactNode, useState } from "react";
import styled from "styled-components";

import { WidgetFrameTitleInformationIcon } from "components/layout/WidgetFrame/WidgetFrameTitle/WidgetFrameTitleInformation/WidgetFrameTitleInformationIcon";
import { WidgetFrameTitleInformationTooltip } from "components/layout/WidgetFrame/WidgetFrameTitle/WidgetFrameTitleInformation/WidgetFrameTitleInformationTooltip";
import { TOOLTIP_CLASS } from "components/layout/WidgetFrame/WidgetFrameTitle/WidgetFrameTitleInformation/widget-frame-title-information.consts";
import { Dialog } from "components/ui/Dialog";

const WidgetFrameTitleModalButton = styled.button``;

const WidgetFrameTitleModalStyled = styled.div`
    position: relative;

    line-height: 1.5rem;

    & button {
        padding: 0;

        background: none;
        border: none;

        &:hover {
            & + .${TOOLTIP_CLASS} {
                display: flex;

                opacity: 1;
            }
        }
    }
`;

type Props = {
    children: ReactNode;
};

// Reused styling from WidgetFrameTitleInformation
const WidgetFrameTitleModal = (props: Props) => {
    const { children } = props;
    const [show, setShow] = useState(false);

    return (
        <>
            <WidgetFrameTitleModalStyled>
                <WidgetFrameTitleModalButton onClick={() => setShow(true)}>
                    <WidgetFrameTitleInformationIcon icon={"lely-icon-graph"} />
                </WidgetFrameTitleModalButton>
                <WidgetFrameTitleInformationTooltip className={TOOLTIP_CLASS}>
                    <div>Detailed View</div>
                </WidgetFrameTitleInformationTooltip>
            </WidgetFrameTitleModalStyled>
            <Dialog open={show} onOpenChange={() => setShow(false)}>
                {/* Dialog.Content should be specified in the children */}
                {children}
            </Dialog>
        </>
    );
};

export { WidgetFrameTitleModal };
