// @ts-strict-ignore
import { isEqual } from "lodash";
import { useRef, useState, useEffect } from "react";
import { Virtuoso } from "react-virtuoso";

import { Item } from "components/components-deprecated/tables/components/MultiSelectFilterItem";
import { removeDuplicates } from "components/components-deprecated/tables/tables.helper";
import { PAGE_ID } from "components/layout/Page/page.utils";
import { Popover } from "components/ui/Popover";
import { Flex, Loading, Icon, Portal } from "components/ui-deprecated";
import { length, type DataWrapper, map } from "deprecated/data-wrapper";
import { OUTSIDE_CLICK_NO_CLOSE_CLASS, useOutsideClick } from "hooks/useOutsideClick";

import "components/components-deprecated/tables/components/MultiSelectFilter.less";
import "components/components-deprecated/tables/components/WidgetSelectorCheckbox.less";

export const SCROLL_HEIGHT = 300;
export const OPTION_HEIGHT = 28;

export type MultiSelectFilterProps = {
    placeholderText: string;
    data: null | DataWrapper;
    selected: Array<string>;
    setSelected: (selectedItems: Array<string>) => void;
    valueField: string;
    initSearchValue: string;
    includeAll: boolean;
};

MultiSelectFilter.defaultProps = {
    placeholderText: "Placeholder",
    setSelected: () => null,
    initSearchValue: "",
    includeAll: true,
};

export default function MultiSelectFilter(props: MultiSelectFilterProps): JSX.Element {
    const { placeholderText, valueField, includeAll, data, selected, setSelected } = props;

    // Ref and states
    const inputRef = useRef(null);
    const [dropdown, setDropdown] = useState(false);
    const scrollSection = document.getElementById(PAGE_ID);

    const allOptions = map(data, (row) => row[valueField]);

    // Create array to loop through for filtering
    const [searchTerms, setSearchTerms] = useState<Array<string>>([]);

    const resetFilter = () => {
        // Reset
        setSearchTerms(selected);
    };

    const applyFilter = () => {
        let clean = removeDuplicates(searchTerms);
        clean = clean.filter((e) => e !== "");

        setSelected(clean);
        setDropdown(false);
    };

    useEffect(() => {
        // Account for when search term should be updated from context
        const changedFromContext = !isEqual(selected, searchTerms);
        if (changedFromContext && selected.length > 0) {
            // TODO: Refine this
            setSearchTerms([...selected]);
        }
    }, [selected]);

    // Hide dropdown when main section is scrolled
    // This can be improved by calculating position of input and then moved according to scrolled distance
    // might be performance bottleneck for low-end devices
    useEffect(() => {
        if (scrollSection !== null) {
            scrollSection.addEventListener("scroll", () => {
                setDropdown(false);
            });
        }

        return () => {
            if (scrollSection !== null) {
                scrollSection.removeEventListener("scroll", () => {
                    setDropdown(false);
                });
            }
        };
    }, [scrollSection]);

    let dropdownHeight = SCROLL_HEIGHT;

    if (data !== null) {
        dropdownHeight = length(data) * OPTION_HEIGHT > SCROLL_HEIGHT ? SCROLL_HEIGHT : length(data) * OPTION_HEIGHT;
    }

    const getMultiSelectText = () => {
        let text = "";
        if (selected.length === 1) {
            text = selected[0];
        } else if (selected.length >= 2) {
            text = `${selected[0]}, +${selected.length - 1} other${selected.length >= 3 ? "s" : ""}`;
        }
        return text;
    };

    useOutsideClick(inputRef, () => setDropdown(false));

    const selectTerm = (termToSelect: string) => {
        if (searchTerms.includes(termToSelect)) {
            setSearchTerms(searchTerms.filter((term) => term !== termToSelect));
        } else {
            setSearchTerms([...searchTerms, termToSelect]);
        }
    };

    const selectAllTerms = () => {
        if (searchTerms.length !== allOptions.length) {
            setSearchTerms([...allOptions]);
        } else {
            setSearchTerms([]);
        }
    };

    const includeAllIndexShift = includeAll ? 2 : 0;

    return (
        <Loading show={data === null}>
            {data && (
                <div className={"select"} ref={inputRef}>
                    <Flex className={"select-input-wrapper"} alignItems={"center"}>
                        <input
                            type={"text"}
                            onFocus={() => {
                                setDropdown(true);
                            }}
                            className={"select-input"}
                            placeholder={placeholderText}
                            value={getMultiSelectText()}
                            ref={inputRef}
                        />
                        <span
                            className={"select-icon"}
                            onClick={() => {
                                setDropdown((prev) => !prev);
                            }}
                        >
                            <Icon icon={`angle-${dropdown ? "up" : "down"}`} />
                        </span>
                    </Flex>

                    {dropdown && (
                        <Portal
                            zIndex={50}
                            dropUp={true}
                            portalHeightPx={dropdownHeight + 100} // Add padding for buttons at the bottom
                        >
                            <Popover variant={"bottom"} $isPortal={true} $zIndex={50}>
                                <div style={{ display: "inline" }}>
                                    <div className={["select-dropdown", OUTSIDE_CLICK_NO_CLOSE_CLASS].join(" ")}>
                                        <Virtuoso
                                            style={{ height: `${dropdownHeight}px` }}
                                            totalCount={data.length + includeAllIndexShift}
                                            itemContent={(index) =>
                                                index === 0 && includeAll ? (
                                                    <Item
                                                        item="All"
                                                        active={
                                                            searchTerms.length === allOptions.length &&
                                                            !searchTerms.includes("Empty")
                                                        }
                                                        handleItemClick={selectAllTerms}
                                                    />
                                                ) : index === 1 && includeAll ? (
                                                    <Item
                                                        item="Empty"
                                                        active={!!searchTerms.includes("Empty")}
                                                        handleItemClick={() =>
                                                            searchTerms.includes("Empty")
                                                                ? setSearchTerms([...allOptions])
                                                                : setSearchTerms(["Empty"])
                                                        }
                                                    />
                                                ) : (
                                                    <Item
                                                        item={allOptions[index - includeAllIndexShift]}
                                                        active={searchTerms.includes(
                                                            allOptions[index - includeAllIndexShift]
                                                        )}
                                                        handleItemClick={() =>
                                                            selectTerm(allOptions[index - includeAllIndexShift])
                                                        }
                                                    />
                                                )
                                            }
                                        />
                                    </div>
                                    <Flex
                                        flexDirection={"row"}
                                        justifyContent={"center"}
                                        className={["multi-select-actions", OUTSIDE_CLICK_NO_CLOSE_CLASS].join(" ")}
                                    >
                                        <Flex className={"action-button cancel-filter"} onClick={resetFilter}>
                                            Cancel
                                        </Flex>
                                        <Flex
                                            className={`action-button apply-filter ${
                                                !isEqual(selected, searchTerms) ? "active" : ""
                                            }`}
                                            onClick={() => {
                                                if (!isEqual(selected, searchTerms)) {
                                                    applyFilter();
                                                }
                                            }}
                                        >
                                            Apply Selection
                                        </Flex>
                                    </Flex>
                                </div>
                            </Popover>
                        </Portal>
                    )}
                </div>
            )}
        </Loading>
    );
}
