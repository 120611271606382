// @ts-strict-ignore
import { sidebarItems } from "components/layout/Sidebar/sidebar-items";
import { GetSidebarItemFn, SidebarItemOptions } from "components/layout/Sidebar/sidebar.types";
import { getAreaRoutes } from "routes/area-routes";
import { RouteName } from "routes/routes.types";
import { useAppSelector } from "store";

const getSidebarItemsByRoutes =
    (userRoutes: RouteName[], sidebarItemOptions: SidebarItemOptions) =>
    (userSidebarItems: GetSidebarItemFn[], sidebarItemFn: GetSidebarItemFn) => {
        const sidebarItem = sidebarItemFn(sidebarItemOptions);

        if (sidebarItem === null) return userSidebarItems;

        if (sidebarItem._type === "link") {
            if (userRoutes.includes(sidebarItem.route)) {
                return [...userSidebarItems, sidebarItemFn];
            } else {
                return userSidebarItems;
            }
        } else if (sidebarItem._type === "menu") {
            const menuItems = sidebarItem.links.reduce(getSidebarItemsByRoutes(userRoutes, sidebarItemOptions), []);
            if (menuItems.length > 0) {
                return [...userSidebarItems, sidebarItemFn];
            } else {
                return userSidebarItems;
            }
        }
    };

export const useUserSidebarItems = () => {
    const userAreas = useAppSelector((state) => state.user.areas);

    // Developers can see everything
    const developerMode = useAppSelector((state) => state.user.developerMode);
    if (developerMode) return sidebarItems;

    const userRoutes = getAreaRoutes(userAreas);

    const userSidebarItems = sidebarItems.reduce(getSidebarItemsByRoutes(userRoutes, { userAreas, developerMode }), []);
    return userSidebarItems;
};
