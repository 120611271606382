// @ts-strict-ignore
import { type ChartConfiguration } from "components/ui/Chart/chart.types";
import { CHART_FONT } from "components/ui/Chart/chart.utils";

const { format: formatNumber } = new Intl.NumberFormat();

type Options = {
    getTotalValue?: (total: number) => string;
};

export type { Options as CreateRadarChartDisplayValuesPluginOptions };

export const createRadarChartDisplayValuesPlugin = (
    options?: Options
): ChartConfiguration<"radar">["plugins"][number] => {
    const getTotalValue = options?.getTotalValue ?? formatNumber;

    return {
        id: "radarChartDisplayValuesPlugin",

        afterDatasetsDraw(chart) {
            const {
                ctx,
                data: { datasets },
            } = chart;

            datasets.forEach((dataset, datasetIndex) => {
                const { data: dataPoints } = chart.getDatasetMeta(datasetIndex);
                const parsed = dataPoints.map((point, pointIndex) => ({
                    ...point,
                    value: dataset.data[pointIndex],
                }));

                parsed.forEach(({ x, y, value }) => {
                    const TEXT_HEIGHT = 12;
                    const FONT_WEIGHT = 500;
                    const OFFSET = {
                        x: 2,
                        y: 2,
                    };

                    ctx.font = `${FONT_WEIGHT} ${TEXT_HEIGHT}px ${CHART_FONT}`;
                    ctx.strokeStyle = "white";
                    ctx.fillStyle = "black";
                    ctx.textAlign = "start";
                    ctx.strokeText(getTotalValue(Number(value)), x + OFFSET.x, y + OFFSET.y);
                    ctx.fillText(getTotalValue(Number(value)), x + OFFSET.x, y + OFFSET.y);
                });
            });
        },
    };
};
