// @ts-strict-ignore
import { SearchResultListItem } from "components/layout/Navbar/ApplicationSearch/SearchBar/SearchResultMenu/SearchResultListItem";
import { DeviceRow } from "components/layout/Navbar/ApplicationSearch/application-search.types";
import { routes } from "routes";
import { generatePath, toDeviceTypeParam } from "routes/routes.utils";

type Props = {
    data: DeviceRow;
    onClick: () => void;
    className?: string;
};

export const DeviceListItem = (props: Props) => {
    const { data } = props;

    return (
        <SearchResultListItem
            chip={data.DeviceType ?? "Unknown device"}
            label={data.DeviceSerialNumber}
            title={"Device"}
            url={generatePath(routes.customerDeviceDetail, {
                params: {
                    customerNumber: data.CustomerNumber,
                    deviceType: toDeviceTypeParam(data.DeviceCategory),
                    deviceSerialNumber: data.DeviceSerialNumber,
                },
            })}
            icon={data.DeviceCategory === "Astronaut" ? "lely-icon-astronaut" : "lely-icon-robot"}
            onClick={props.onClick}
            className={props.className}
        />
    );
};
