// @ts-strict-ignore
import styled from "styled-components";

import { SidebarNavigationItem } from "components/layout/Sidebar/SidebarNavigation/SidebarNavigationItem";
import { SidebarNavigationItemMenu } from "components/layout/Sidebar/SidebarNavigation/SidebarNavigationItemMenu";
import { useUserSidebarItems } from "components/layout/Sidebar/hooks/useUserSidebarItems";
import { useUserAreas } from "hooks/useUserAreas";
import { routes } from "routes";
import { generatePath } from "routes/routes.utils";
import { useAppSelector } from "store";

const SidebarNavigationStyled = styled.nav`
    overflow-y: auto;

    flex-grow: 1;
`;

type Props = {
    sidebarCollapsed: boolean;
    onItemClick: () => void;
};

const SidebarNavigation = (props: Props) => {
    const { sidebarCollapsed, onItemClick } = props;

    const userAreas = useUserAreas();
    const developerMode = useAppSelector((state) => state.user.developerMode);

    const userSidebarItems = useUserSidebarItems();

    return (
        <SidebarNavigationStyled>
            {userSidebarItems.map((getItem, index) => {
                const item = getItem({ userAreas, developerMode });
                if (!item) return null;

                return item._type === "link" ? (
                    <SidebarNavigationItem
                        key={item.route}
                        path={generatePath(routes[item.route], item.routeOptions as any)} // FIXME: remove any
                        title={item.title}
                        icon={item.icon}
                        sidebarCollapsed={sidebarCollapsed}
                        onClick={onItemClick}
                    />
                ) : (
                    <SidebarNavigationItemMenu
                        key={`${item.title}-${index}`}
                        item={item}
                        sidebarCollapsed={sidebarCollapsed}
                        onClick={onItemClick}
                    />
                );
            })}
        </SidebarNavigationStyled>
    );
};

export { SidebarNavigation };
