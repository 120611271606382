import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useAppDispatch } from "store";
import { clearConfluencePageId } from "store/slices/documentation.slice";

/**
 * Close the documentation window when the user navigates to a different page.
 */
export const useCloseDocumenationOnNavigate = () => {
    const dispatch = useAppDispatch();
    const location = useLocation();

    useEffect(() => {
        const closeDocumentation = () => {
            dispatch(clearConfluencePageId());
        };

        closeDocumentation();
    }, [location, dispatch]);
};
