import { CSSProperties } from "react";
import styled from "styled-components";

import { TableHeaderCellStyled } from "components/ui/table-final-saviour/Table/TableHeaderCell/TableHeaderCell";
import { Icon } from "components/ui-deprecated";

export const TableHeaderCellSortableStyled = styled(TableHeaderCellStyled)`
    cursor: pointer;
`;

export const TableHeaderCellSortableContent = styled.div<{ $justifyContent?: CSSProperties["justifyContent"] }>`
    display: flex;
    align-items: center;
    justify-content: ${({ $justifyContent }) => $justifyContent ?? "flex-start"};
    gap: 0.25rem;
`;

export const TableHeaderCellSortableSortIcon = styled(Icon)`
    color: ${({ theme }) => theme.color.textGrayDairyDarker};
    font-size: 0.75rem;
`;
