import styled from "styled-components";

import { Icon } from "components/ui-deprecated";
import { MAIN_DOCUMENTATION_CONFLUENCE_PAGE_ID } from "features/documentation/documentation.utils";
import { useAppDispatch } from "store";
import { setConfluencePageId } from "store/slices/documentation.slice";

const DocumentationButtonStyled = styled.button`
    position: absolute;
    bottom: 1rem;
    right: 0;

    width: 2rem;
    height: 2rem;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: ${({ theme }) => theme.color.lelyRed};
    border: none;
    border-start-start-radius: 0.5rem;
    border-end-start-radius: 0.5rem;
    box-shadow: 0 0 0.25rem #c30a1466;

    color: #fff;

    font-size: 1rem;

    cursor: pointer;

    &:hover {
        background-color: ${({ theme }) => theme.color.lelyRedDark};
        box-shadow: 0 0 1rem #c30a1466;
    }
`;

const DocumentationButton = () => {
    const dispatch = useAppDispatch();

    const handleOpen = () => {
        dispatch(setConfluencePageId(MAIN_DOCUMENTATION_CONFLUENCE_PAGE_ID));
    };

    return (
        <DocumentationButtonStyled onClick={handleOpen}>
            <Icon icon="info" />
        </DocumentationButtonStyled>
    );
};

export { DocumentationButton };
