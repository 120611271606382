import styled from "styled-components";

import { useSendUserEvent } from "hooks/user-event/useSendUserEvent";
import { useAppDispatch } from "store";
import { setConfluencePageId } from "store/slices/documentation.slice";

const Trigger = styled.button`
    cursor: pointer;
`;

type Props = {
    pageId?: number;
    title: string;
    children?: any;
};

export const DocumenatationTrigger: React.FC<Props> = (props: Props) => {
    const { pageId, title, children } = props;

    const dispatch = useAppDispatch();
    const sendUserEvent = useSendUserEvent();

    if (!pageId) {
        return children;
    }

    const handleOpenConfluenceWidgetDocs = () => {
        dispatch(setConfluencePageId(pageId));

        sendUserEvent({
            type: "click:documentation",
            value: title,
        });
    };

    return (
        <Trigger type={"button"} onClick={handleOpenConfluenceWidgetDocs}>
            {children}
        </Trigger>
    );
};
