// @ts-strict-ignore
import { fromJS } from "deprecated/data-wrapper/parse";
import type { DataWrapper, Row, NestedRow } from "deprecated/data-wrapper/types";

export function map<T>(data: DataWrapper, callback: (row: Row, index: number) => T): Array<T> {
    const output = new Array(data.length);

    for (let i = 0; i < data.length; i++) {
        const row = Object.keys(data.data).reduce((row, field) => {
            row[field] = data.data[field].data[i];
            return row;
        }, {});
        output[i] = callback(row, i);
    }

    return output;
}
export function nestedMap<T>(data: DataWrapper, callback: (row: NestedRow, index: number) => T): Array<T> {
    const output = new Array(data.length);

    for (let i = 0; i < data.length; i++) {
        const row = Object.keys(data.data).reduce((row, field) => {
            row[field] = data.data[field].data[i];
            return row;
        }, {}) as any;

        if (data.nested !== null) {
            row.data = data.nested[i] ? nestedMap(data.nested[i], (row) => row) : null;
        }

        output[i] = callback(row, i);
    }

    return output;
}
// TODO: better performance
export function filter(data: DataWrapper, callback: (row: Row, i: number) => boolean): DataWrapper {
    const result = fromJS(
        nestedMap(data, (nestedRow) => nestedRow).filter((nestedRow, i) => {
            const { data, ...row } = nestedRow;
            return callback(row, i);
        })
    );

    if (result instanceof Error) {
        throw result;
    } else {
        return result;
    }
}
export function take(data: DataWrapper, count: number): DataWrapper {
    return filter(data, (row, i) => i < count);
}
// TODO: better performance
export function sort(data: DataWrapper, compare: (row1: Row, row2: Row) => number): DataWrapper {
    const result = fromJS(
        nestedMap(data, (nestedRow) => nestedRow).sort((nr1, nr2) => {
            const { data: d1, ...row1 } = nr1;
            const { data: d2, ...row2 } = nr2;
            return compare(row1, row2);
        })
    );

    if (result instanceof Error) {
        throw result;
    } else {
        return result;
    }
}
export function hasEqualShape(first: DataWrapper, second: DataWrapper): boolean {
    const firstFields = Object.keys(first.data);
    const secondFields = Object.keys(second.data);

    if (firstFields.length === 0 || secondFields.length === 0) {
        // Empty data wrapper is basically equally shaped as any other data wrapper
        return true;
    }

    if (firstFields.length !== secondFields.length) {
        return false;
    } else {
        firstFields.sort();
        secondFields.sort();

        for (let i = 0; i < firstFields.length; i++) {
            if (firstFields[i] !== secondFields[i]) {
                return false;
            } else if (first[firstFields[i]].type !== second[secondFields[i]].type) {
                return false;
            }
        }

        return true;
    }
}
export function skip(data: DataWrapper, amount: number): DataWrapper {
    return filter(data, (row, i) => i >= amount);
}
export function removeDuplicates(array: Array<string>): Array<string> {
    return array.filter((value, index) => array.indexOf(value) === index);
}
