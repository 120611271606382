// @ts-strict-ignore
import { Preset } from "components/ui/DatePicker/Presets/Preset";
import styles from "components/ui/DatePicker/Presets/Presets.module.css";
import { PresetDate, PresetDateRange } from "components/ui/DatePicker/Presets/presets.types";
import { dateFormat, dayjs, Dayjs } from "components/ui/DatePicker/date.utils";

type DatePresetsProps = {
    presets: PresetDate[];
    selectedDate: Dayjs | null;
    setSelectedDate: (value: Dayjs) => void;
    isDisabledDate?: (value: string) => boolean;
};

type DateRangePresetsProps = {
    presets: PresetDateRange[];
    selectedDate: { from: Dayjs; to: Dayjs } | null;
    setSelectedDate: (value: { from: Dayjs; to: Dayjs }) => void;
    isDisabledDate?: (date: string) => boolean;
};

type Props = DatePresetsProps | DateRangePresetsProps;

const Presets = (props: Props) => {
    const { presets, selectedDate, setSelectedDate, isDisabledDate } = props;

    return (
        <div className={styles.presets}>
            <div className={styles.presetsTitle}>Presets</div>
            <div className={styles.presetsList}>
                {presets.map((preset, index) => {
                    let isSelected = false;
                    let disabled = false;

                    if (selectedDate) {
                        const isDatePresets = dayjs.isDayjs(selectedDate);
                        if (isDatePresets) {
                            isSelected = selectedDate.isSame(preset.value, "date");

                            if (isDisabledDate) {
                                disabled = isDisabledDate(preset.value.format(dateFormat));
                            }
                        } else {
                            const isSameFrom = selectedDate.from.isSame(preset.value.from, "date");
                            const isSameTo = selectedDate.to.isSame(preset.value.to, "date");
                            isSelected = isSameFrom && isSameTo;

                            if (isDisabledDate) {
                                disabled =
                                    isDisabledDate(preset.value.from.format(dateFormat)) ||
                                    isDisabledDate(preset.value.to.format(dateFormat));
                            }
                        }
                    }

                    return (
                        <Preset
                            key={index}
                            preset={preset}
                            onClick={() => setSelectedDate(preset.value)}
                            isSelected={isSelected}
                            disabled={disabled}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export { Presets };
