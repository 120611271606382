// @ts-strict-ignore
import { Tooltip } from "@datamole/wds-component-tooltip";
import { memo, ReactNode } from "react";
import styled from "styled-components";

import { TableHeaderCellProps } from "components/ui/table-final-saviour/Table/TableHeaderCell/TableHeaderCell";
import {
    TableHeaderCellSortableStyled,
    TableHeaderCellSortableContent,
    TableHeaderCellSortableSortIcon,
} from "components/ui/table-final-saviour/Table/TableHeaderCell/TableHeaderCellSortable/table-header-cell-sortable.styled-components";
import { SortOrder } from "components/ui/table-final-saviour/Table/TableHeaderCell/TableHeaderCellSortable/table-header-cell-sortable.types";
import { sortIconMap } from "components/ui/table-final-saviour/Table/TableHeaderCell/TableHeaderCellSortable/table-header-cell-sortable.utils";
import { Row } from "components/ui/table-final-saviour/Table/table.types";

const TooltipTriggerStyled = styled(Tooltip.Trigger)`
    display: flex;
    align-items: center;
    gap: 0.25rem;
    text-decoration: underline dotted;
`;

interface Props<T extends Row> extends TableHeaderCellProps<T> {
    tooltip: ReactNode;
    sortOrder: SortOrder;
    onClick: () => void;
}

const TableHeaderCellSortableWithTooltip = <T extends Row>(props: Props<T>) => {
    const { align, sortOrder } = props;
    const icon = sortIconMap[sortOrder];

    const justifyContentMap = {
        left: "flex-start",
        center: "center",
        right: "flex-end",
    };
    const justifyContent = align ? justifyContentMap[align] : undefined;

    return (
        <TableHeaderCellSortableStyled
            className={props.className}
            $align={props.align}
            $width={props.width}
            onClick={props.onClick}
        >
            <TableHeaderCellSortableContent $justifyContent={justifyContent}>
                <Tooltip>
                    <TooltipTriggerStyled>
                        {props.children}
                        <TableHeaderCellSortableSortIcon icon={icon} />
                    </TooltipTriggerStyled>
                    <Tooltip.Content
                        style={{
                            zIndex: 10,
                        }}
                    >
                        {props.tooltip}
                    </Tooltip.Content>
                </Tooltip>
            </TableHeaderCellSortableContent>
        </TableHeaderCellSortableStyled>
    );
};

const TableHeaderCellSortableWithTooltipMemo = memo(TableHeaderCellSortableWithTooltip);

export { TableHeaderCellSortableWithTooltipMemo as TableHeaderCellSortableWithTooltip };
export type { Props as TableHeaderCellSortableWithTooltipProps };
