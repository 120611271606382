// @ts-strict-ignore
import { TouchEvent, useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import { StickyHeaderContent } from "components/layout/Page/PageStickyHeader/StickyHeaderContent";
import { StickyHeaderFixedContainer } from "components/layout/Page/PageStickyHeader/StickyHeaderFixedContainer";
import { PAGE_ID } from "components/layout/Page/page.utils";

const PageStickyHeaderStyled = styled.div<{ $show: boolean }>`
    display: ${({ $show }) => ($show ? "block" : "none")};

    position: absolute;
    top: 67px;
    right: 0;
    z-index: 10;

    width: ${({ theme }) => (theme.sidebarCollapsed ? "calc(100% - 8rem)" : "calc(100% - 18rem)")};

    container-type: inline-size;
    container-name: page;

    transition: all 0.25s ease-in;

    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        width: 100%;
    }
`;

// 10 REM -> page padding + title + content padding
const ENABLE_AFTER_HEIGHT_DEFAULT = 160;

type Props = {
    children: React.ReactNode;
    enableAfterHeight?: number;
};

const PageStickyHeader = (props: Props) => {
    const [show, setShow] = useState(false);

    // On no horizontal scroll (or very slight scroll down) do nothing,
    // on scroll up hide, otherwise show
    const handleHorizontalScroll = useCallback(
        (deltaY: number) => {
            // scrolled distance from top
            const fromTop = document.getElementById(PAGE_ID).scrollTop;
            const isScrolledNearTop = fromTop < (props.enableAfterHeight ?? ENABLE_AFTER_HEIGHT_DEFAULT);

            if (deltaY === 0 || deltaY === -1) {
                return;
            } else if (deltaY > -1 || isScrolledNearTop) {
                setShow(false);
            } else {
                setShow(true);
            }
        },
        [props.enableAfterHeight]
    );

    useEffect(() => {
        const handleScroll = (event: WheelEvent) => {
            handleHorizontalScroll(event.deltaY);
        };

        window.addEventListener("wheel", handleScroll, { capture: true });
        return () => {
            window.removeEventListener("wheel", handleScroll, { capture: true });
        };
    }, [handleHorizontalScroll]);

    // Remember last touch position
    const [touchClientY, setTouchClientY] = useState(0);
    useEffect(() => {
        const handleTouchMove = (event: TouchEvent) => {
            const deltaY = touchClientY - event.touches[0].clientY;

            handleHorizontalScroll(deltaY);
            setTouchClientY(event.touches[0].clientY);
        };

        window.addEventListener("touchmove", handleTouchMove as any, { capture: true });
        return () => {
            window.removeEventListener("touchmove", handleTouchMove as any, { capture: true });
        };
    }, [handleHorizontalScroll, touchClientY]);

    return (
        <PageStickyHeaderStyled $show={show}>
            <StickyHeaderFixedContainer $show={show}>
                <StickyHeaderContent>{props.children}</StickyHeaderContent>
            </StickyHeaderFixedContainer>
        </PageStickyHeaderStyled>
    );
};

export { PageStickyHeader };
