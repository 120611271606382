import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";

import { Content } from "components/layout/Content";
import { Feedback } from "components/layout/Feedback";
import { Navbar } from "components/layout/Navbar";
import { PageLoader } from "components/layout/PageLoader";
import { Sidebar } from "components/layout/Sidebar/Sidebar";
import { useSidebarCollapse } from "components/layout/Sidebar/hooks/useSidebarCollapse";
import { NotificationBoard } from "components/ui-deprecated";
import { Documentation } from "features/documentation";
import { theme } from "styles/theme";

const LayoutStyled = styled.div`
    position: relative;

    height: 100%;
    width: 100%;

    display: grid;

    grid-template-columns: max-content 1fr;
    grid-template-rows: max-content 1fr;
    grid-template-areas:
        "sidebar navbar"
        "sidebar content";
`;

const Layout = () => {
    const sidebarCollapse = useSidebarCollapse();
    const { sidebarCollapsed } = sidebarCollapse;

    return (
        <ThemeProvider theme={{ ...theme, sidebarCollapsed }}>
            <LayoutStyled>
                <Sidebar {...sidebarCollapse} />
                <Navbar />
                <Content>
                    <Suspense fallback={<PageLoader />}>
                        <Outlet />
                    </Suspense>
                </Content>
                <Feedback />
                <Documentation />
                <NotificationBoard />
            </LayoutStyled>
        </ThemeProvider>
    );
};

export { Layout };
