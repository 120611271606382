// @ts-strict-ignore
import { WidgetFrame } from "components/layout/WidgetFrame";
import {
    WidgetWithOptionalQueryParameters,
    WidgetWithQueryParameters,
} from "components/layout/WidgetFrame/widget.types";
import { KPI, toKPIChartDataPoints, formatKPIValue } from "components/ui/KPI";
import { useDataSource } from "hooks/useDataSource";

type Props = WidgetWithQueryParameters<"dateFrom" | "dateTo"> & WidgetWithOptionalQueryParameters<"customerNumber">;

type ChartDataDto = {
    Date: string;
    ChartValue: number;
};

const ServiceCostMilkKPIWidget = (props: Props) => {
    const { queryParameters } = props;

    const dataSourceParameters = { ...queryParameters, customerNumber: queryParameters.customerNumber ?? "" };

    const chartDataSource = useDataSource<ChartDataDto[]>("SM_ServiceCostPerLiterMilk", dataSourceParameters);

    const { data, lastDataPoint } = toKPIChartDataPoints(chartDataSource?.data ?? [], {
        dateKey: "Date",
        valueKey: "ChartValue",
        getLabel: (x) => formatKPIValue(x, { numberPrecision: 4 }),
    });

    return (
        <>
            <WidgetFrame.Title title={"Service Cost / 1L of Milk"} icon={"lely-icon-milk"} size={"sm"}>
                <WidgetFrame.Title.Information lastImportType={"middleware"} pageId={333086866} />
            </WidgetFrame.Title>
            <WidgetFrame.KPI dependencies={[chartDataSource]}>
                <KPI.LastValue title="Last Selected Full Month" value={lastDataPoint?.label} />
                <KPI.Chart data={data} />
            </WidgetFrame.KPI>
        </>
    );
};

export { ServiceCostMilkKPIWidget };
