// @ts-strict-ignore
import { WidgetFrame } from "components/layout/WidgetFrame";
import { WidgetWithQueryParameters } from "components/layout/WidgetFrame/widget.types";
import {
    KPIDataSourceChartData,
    KPI,
    KPIDataSourceTextData,
    toKPIChartDataPoints,
    toKPITextData,
} from "components/ui/KPI";
import { useDataSource } from "hooks/useDataSource";

type Props = WidgetWithQueryParameters<"dateFrom" | "dateTo">;

const PMReportsPerAstronautKPIWidget = (props: Props) => {
    const { queryParameters } = props;

    const dataSourceParameters = { ...queryParameters, KPI: 4 };

    const chartDataSource = useDataSource<KPIDataSourceChartData[]>(
        "Dashboard_ReportsPerDevice_Chart",
        dataSourceParameters
    );
    const textDataSource = useDataSource<KPIDataSourceTextData[]>(
        "Dashboard_ReportsPerDevice_Text",
        dataSourceParameters
    );
    const dataSources = [chartDataSource, textDataSource];

    const { data, lastDataPoint } = toKPIChartDataPoints(chartDataSource?.data ?? []);
    const additionalValue = toKPITextData(textDataSource?.data ?? []);

    return (
        <>
            <WidgetFrame.Title
                title={"Preventive Maintenance Reports per Astronaut"}
                icon={"lely-icon-reports"}
                size={"sm"}
            >
                <WidgetFrame.Title.Information lastImportType={"middleware"} pageId={335577199}>
                    Result for each completed month = Number of PM reports (nPM) / Number of active Astronauts (nA).
                    Click on the (i) for more details.
                </WidgetFrame.Title.Information>
            </WidgetFrame.Title>
            <WidgetFrame.KPI dependencies={dataSources}>
                <KPI.LastValue title="Last Selected Full Month" value={lastDataPoint?.label} />
                {additionalValue && <KPI.AdditionalValue title={"Target"} value={additionalValue} />}
                <KPI.Chart data={data} />
            </WidgetFrame.KPI>
        </>
    );
};

export { PMReportsPerAstronautKPIWidget };
