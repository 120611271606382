// @ts-strict-ignore
import { createSelector } from "@reduxjs/toolkit";

export const primaryActiveRoleSelector = createSelector(
    [
        (state) => {
            return state.user.primaryCombinedRole.children;
        },
        (state) => {
            return state.user.primaryActiveRoleId;
        },
    ],
    (primaryRoles, primaryActiveRoleId) => {
        return primaryRoles.find((role) => role.id === primaryActiveRoleId);
    }
);
