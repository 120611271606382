import { Button } from "@datamole/wds-component-button";
import styled from "styled-components";

import { Icon } from "components/ui-deprecated";

const Header = styled.div`
    padding: 1rem 1.5rem;

    display: flex;
    justify-content: space-between;

    background-color: var(--wds-color-brand-main);
    color: var(--wds-color-brand-main-contrast);
`;

const HeaderTitle = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;

    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.25rem;

    svg {
        font-size: 1rem;
    }
`;

const HeaderCloseButton = styled(Button)`
    margin: -0.5rem;

    --wds-button-text-color-icon: #fff;
`;

type Props = {
    handleClose: () => void;
};

const DocumentationWindowHeader = (props: Props) => {
    const { handleClose } = props;

    return (
        <Header>
            <HeaderTitle>
                <Icon icon="info" />
                Documentation
            </HeaderTitle>
            <HeaderCloseButton variant={"text"} onClick={handleClose}>
                <Button.Icon>
                    <Icon icon={"times"} />
                </Button.Icon>
            </HeaderCloseButton>
        </Header>
    );
};

export { DocumentationWindowHeader };
