// @ts-strict-ignore
import { Formik, Form } from "formik";
import * as Yup from "yup";

import { toSelectOptions } from "components/layout/Sidebar/SidebarControls/ClusterFilter/cluster-filter.utils";
import { SidebarControlContentActions } from "components/layout/Sidebar/SidebarControls/SidebarControl/SidebarControlContent/SidebarControlContentActions";
import { SidebarControlContentTitle } from "components/layout/Sidebar/SidebarControls/SidebarControl/SidebarControlContent/SidebarControlContentTitle";
import { SidebarControlToggleFunctionProp } from "components/layout/Sidebar/sidebar.types";
import { Button } from "components/ui/Button";
import { DataContent } from "components/ui/DataContent";
import { TreeMultiSelect, Loading } from "components/ui-deprecated";
import { useSendUserEvent } from "hooks/user-event/useSendUserEvent";
import { useAppDispatch, useAppSelector } from "store";
import { useGetUserClustersQuery, useSetUserClustersMutation } from "store/services/cluster-filter.service";
import { dataSourceApi, TAG_DATA_SOURCE } from "store/services/data-source.service";
import { TAG_USER, userApi } from "store/services/user.service";
import { setClusters as setClustersLocally } from "store/slices/user.slice";
import { GUID } from "types/common.types";
import { UserCluster } from "types/user.types";

type FormValues = {
    clusters: GUID[];
};

const validationSchema = Yup.object({
    clusters: Yup.array(Yup.string()),
});

type Props = SidebarControlToggleFunctionProp & {
    selectedClusters: UserCluster[];
};

const ClusterFilterContent = (props: Props) => {
    const { selectedClusters, handleToggleOpen } = props;

    const userId = useAppSelector((state) => state.user.id);

    const clustersDataSource = useGetUserClustersQuery({ userId });
    const { data: clusters } = clustersDataSource;

    const clusterSelectOptions = toSelectOptions({ clusters });
    const initialSelectedClusters = selectedClusters.map((m) => m.id);

    const [setClusters] = useSetUserClustersMutation();

    const dispatch = useAppDispatch();
    const sendUserEvent = useSendUserEvent();

    const handleSubmit = async (data: FormValues) => {
        try {
            const clustersWithNames = data.clusters.map((clusterId) => ({
                id: clusterId,
                name: clusters.find((cluster) => cluster.id === clusterId)?.name ?? "Unknown cluster",
            }));

            await setClusters({ userId, clusterIds: data.clusters }).unwrap();
            dispatch(dataSourceApi.util.invalidateTags([TAG_DATA_SOURCE]));
            dispatch(userApi.util.invalidateTags([TAG_USER]));
            dispatch(setClustersLocally(clustersWithNames));
            handleToggleOpen();

            sendUserEvent({
                type: "select:clusters",
                value: clustersWithNames.map((cluster) => cluster.name).join(", "),
            });
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err);
        }
    };

    return (
        <DataContent dependencies={[clustersDataSource]}>
            <Formik<FormValues>
                initialValues={{
                    clusters: initialSelectedClusters,
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize={true}
            >
                {({ dirty, isSubmitting }) => (
                    <Form>
                        <SidebarControlContentTitle>
                            Lely Center Filter
                            <SidebarControlContentActions>
                                <Button variant={"text"} type={"reset"} disabled={!dirty || isSubmitting}>
                                    Reset
                                </Button>
                                <Loading show={isSubmitting} style={{ margin: "auto 0" }}>
                                    <Button type={"submit"} disabled={!dirty || isSubmitting}>
                                        Apply
                                    </Button>
                                </Loading>
                            </SidebarControlContentActions>
                        </SidebarControlContentTitle>
                        <TreeMultiSelect name={"clusters"} options={clusterSelectOptions} />
                    </Form>
                )}
            </Formik>
        </DataContent>
    );
};

export { ClusterFilterContent };
