// @ts-strict-ignore
import { useTranslation } from "react-i18next";

import { Icon } from "components/ui-deprecated";
import type { Formatter } from "deprecated/data-utils";
import { TranslationKey } from "locales/types";

interface OrderColumnHeaderCellProps {
    onClick: () => void;
    formatter: Formatter;
    field: any;
    setIcon: string;
}

const OrderColumnHeaderCell: (props: OrderColumnHeaderCellProps) => JSX.Element = (
    props: OrderColumnHeaderCellProps
) => {
    const { t } = useTranslation();

    const { onClick, formatter, field, setIcon } = props;

    return (
        <div onClick={onClick}>
            {t(formatter(field) as TranslationKey)}{" "}
            <span style={{ marginLeft: "4px" }}>
                <Icon icon={setIcon} />
            </span>
        </div>
    );
};

export default OrderColumnHeaderCell;
