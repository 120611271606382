import { useCallback, useMemo, useState } from "react";

import { DataColumn } from "components/ui/table-final-saviour/Table/columns/data.table-column";
import { isDataColumn } from "components/ui/table-final-saviour/Table/columns/table-columns.utils";
import { Column, Row } from "components/ui/table-final-saviour/Table/table.types";
import { readCellValue } from "components/ui/table-final-saviour/Table/table.utils";
import { normalize } from "utils/string.utils";

type UseSearchOptions<T extends Row> = {
    columns: Column<T>[];
};

export const useSearch = <T extends Row>(options: UseSearchOptions<T>) => {
    const { columns } = options;

    const [searchValue, setSearchValue] = useState("");
    const searchValueNormalized = useMemo(() => normalize(searchValue), [searchValue]);

    const applySearch = useCallback(
        (data: T[]) =>
            data?.filter((row) => {
                const columnStrings = (columns as DataColumn<T>[]).filter(isDataColumn).flatMap((column) => {
                    const valueString = readCellValue({ column, row });
                    const searchCandidates = [normalize(valueString)];

                    // NOTE: If the column is a number, we also want to search by the stringified number not only localized number
                    if (
                        column.dataType === "number" &&
                        row[column.dataKey] !== undefined &&
                        row[column.dataKey] !== null
                    ) {
                        const stringifiedNumber = row[column.dataKey].toString();
                        searchCandidates.push(stringifiedNumber);
                    }

                    return searchCandidates;
                });
                const foundMatchInCell = columnStrings.some((columnString) =>
                    columnString.includes(searchValueNormalized)
                );
                return foundMatchInCell;
            }),
        [searchValueNormalized, columns]
    );

    return { searchValue, setSearchValue, applySearch };
};
