// @ts-strict-ignore
import {
    SearchCategory,
    SearchResultItem,
    SearchResults,
} from "components/layout/Navbar/ApplicationSearch/application-search.types";

export const toSearchResultItem = (category: SearchCategory, data: SearchResults) => {
    let items: SearchResultItem[];

    if (category === "customer") {
        items = data.customers.map((customer) => ({
            sortValue: customer.Name,
            type: "customer",
            data: customer,
        }));
    } else if (category === "device") {
        items = data.devices.map((device) => ({
            sortValue: device.DeviceSerialNumber,
            type: "device",
            data: device,
        }));
    } else if (category === "farm") {
        items = data.farms.map((farm) => ({
            sortValue: farm.FarmLicenseKey,
            type: "farm",
            data: farm,
        }));
    } else if (category === "technician") {
        items = data.technicians.map((technician) => ({
            sortValue: technician.Name,
            type: "technician",
            data: technician,
        }));
    } else {
        items = [
            ...data.customers.map((customer) => ({
                sortValue: customer.Name,
                type: "customerExtended" as const,
                data: customer,
            })),
            ...data.technicians.map((technician) => ({
                sortValue: technician.Name,
                type: "technician" as const,
                data: technician,
            })),
        ];
    }
    return items;
};
