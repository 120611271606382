// @ts-strict-ignore
import { ComponentType, useCallback, useLayoutEffect, useMemo } from "react";
import { TableVirtuoso } from "react-virtuoso";

import { TableProps } from "components/ui/table-final-saviour/Table/Table";
import { TableCell as TableCellDefault } from "components/ui/table-final-saviour/Table/TableCell";
import { TableRowExpandable } from "components/ui/table-final-saviour/Table/TableExpandable/TableRowExpandable";
import { createActionExpandColumn } from "components/ui/table-final-saviour/Table/columns/action-expand.table-column";
import { useAutoHeight } from "components/ui/table-final-saviour/Table/hooks/useAutoHeight";
import { useExpandableRows } from "components/ui/table-final-saviour/Table/hooks/useExpandableRows";
import { useTableComponents } from "components/ui/table-final-saviour/Table/hooks/useTableComponents";
import {
    TableContextExpandable,
    ExpandableRow,
    ExpandedContentComponent,
} from "components/ui/table-final-saviour/Table/table-expandable.types";
import {
    Column,
    GetRowId,
    Row,
    TableCellBaseProps,
    TableComponents,
} from "components/ui/table-final-saviour/Table/table.types";

interface Props<T extends Row> extends TableProps<T> {
    // Callback to get the unique id of a row.
    // This is used to keep track of which rows are expanded.
    getRowId: GetRowId<T>;
    ExpandedContent: ExpandedContentComponent<T>;
}

const TableExpandable = <T extends Row>(props: Props<T>) => {
    const { data, columns, maxHeight, ExpandedContent, getRowId } = props;

    const { getIsExpandedRow, toggleExpandedRow, updateExpandedRows } = useExpandableRows({
        getRowId,
    });

    const dataWithExpandedContent: ExpandableRow<T>[] = useMemo(
        () =>
            data.flatMap((row, index) => {
                const isExpanded = getIsExpandedRow(row);
                if (isExpanded) {
                    return [
                        { ...row, _type: "row" as const, _rowIndex: index },
                        { ...row, _type: "expandable_content" as const, _rowIndex: index },
                    ];
                } else {
                    return [{ ...row, _type: "row" as const, _rowIndex: index }];
                }
            }),
        [data, getIsExpandedRow]
    );

    const columnsWithExpandAction: Column<T>[] = useMemo(() => [createActionExpandColumn<T>(), ...columns], [columns]);

    const { ref, height, hideScrollbar } = useAutoHeight({ maxHeight, rowCount: props.data.length });

    const components: TableComponents = useMemo(() => {
        return {
            TableRow: TableRowExpandable,
            ...props.components,
        };
    }, [props.components]);

    const tableComponents = useTableComponents<T, TableContextExpandable<T>>({
        components,
        ref,
        columns: columnsWithExpandAction,
    });

    const itemContent = useCallback(
        (rowIndex: number, row: T, context: TableContextExpandable<T>) => {
            return columnsWithExpandAction.map((column, columnIndex) => {
                const TableCell: ComponentType<TableCellBaseProps<T>> =
                    column.components?.TableCell ?? TableCellDefault;
                return (
                    <TableCell
                        key={`cell-${rowIndex}-${columnIndex}`}
                        row={row}
                        rowIndex={rowIndex}
                        column={column}
                        columnIndex={columnIndex}
                        context={context}
                    />
                );
            });
        },
        [columnsWithExpandAction]
    );

    // This makes sure that the expanded content is not re-mounted when the state of rows above it changes
    const computeItemKey = useCallback((_: unknown, row: Row) => {
        return `${row._rowIndex}-${row._type}}`;
    }, []);

    const context = useMemo(
        () => ({
            getIsExpandedRow,
            toggleExpandedRow,
            columnCount: columnsWithExpandAction.length,
            ExpandedContent,
            hideScrollbar,
        }),
        [getIsExpandedRow, toggleExpandedRow, columnsWithExpandAction, ExpandedContent, hideScrollbar]
    );

    // Collapse all expanded rows when the data changes
    // With the current setup, the expanded information is tied to the row index,
    // so when the data changes, the new rows with the same index will be expanded
    useLayoutEffect(() => {
        updateExpandedRows(data);
    }, [data, updateExpandedRows]);

    return (
        <div style={{ height }} className={props.className}>
            <TableVirtuoso<T, TableContextExpandable<T>>
                data={dataWithExpandedContent}
                components={tableComponents}
                fixedHeaderContent={tableComponents.TableHeaderRow}
                itemContent={itemContent}
                computeItemKey={computeItemKey}
                overscan={40}
                context={context}
            />
        </div>
    );
};

export { TableExpandable };
export type { Props as TableExpandableProps, ExpandedContentComponent as TableExpandedContentComponent };
