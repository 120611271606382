import styled from "styled-components";

export const PageNavigationTabs = styled.div`
    margin: 2rem 3rem 0 3rem;

    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;

    border-bottom: 2px solid ${({ theme }) => theme.color.lelyRed};

    @container page (max-width: ${({ theme }) => theme.breakpoints.xs}) {
        margin: 2rem 0 0 0;
    }
`;
