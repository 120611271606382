// @ts-strict-ignore
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { fromJS, map as mapData } from "deprecated/data-wrapper";
import { FailuresDataKeys } from "store/enums/firts-time-failures.enum";

interface TipsForDevelopmentState {
    data: any;
    rowData: any;
    innerData: any;
    usedRows: any;
}

const initialState: TipsForDevelopmentState = {
    data: {},
    rowData: [],
    innerData: {},
    usedRows: [],
};

interface TipForDevelopmentPayload {
    tipId: number;
    orderNumber: string;
    innerOrderNumber: string;
}

const ALL_TICKS_FLAG = "green";

const getIndexOfOrderNumber = (data, orderNumber) => {
    return data.findIndex((r) => r[FailuresDataKeys.ORDER_NUMBER] === orderNumber);
};

const tipsForDevelopmentSlice = createSlice({
    name: "tipsForDevelopment",
    initialState,
    reducers: {
        setTipInfo(state, action: PayloadAction<TipForDevelopmentPayload>) {
            const index = state.usedRows.findIndex((item) => item.innerOrderNumber === action.payload.innerOrderNumber);
            if (index !== -1) {
                state.usedRows[index] = action.payload;
            } else {
                state.usedRows.push(action.payload);
            }

            const outerData = state.rowData;
            const expandedData = mapData(state.innerData, (row) => row);
            const dataToReturn = expandedData.map((row) => {
                if (row[FailuresDataKeys.ORDER_NUMBER] === action.payload.innerOrderNumber) {
                    return {
                        ...row,
                        [FailuresDataKeys.TIP_FOR_DEV]: Number(action.payload.tipId),
                    };
                }
                return row;
            });

            const indexOfOrderNumber = getIndexOfOrderNumber(outerData, action.payload.orderNumber);
            const numberOfVisits = outerData[indexOfOrderNumber][FailuresDataKeys.NUMBER_OF_VISITS];
            const rowsWithTips = dataToReturn.filter((row) => row[FailuresDataKeys.TIP_FOR_DEV] !== 0);

            if (rowsWithTips.length === numberOfVisits) {
                outerData[indexOfOrderNumber][FailuresDataKeys.HAS_TIPS_FOR_DEV] = ALL_TICKS_FLAG;
            } else {
                outerData[indexOfOrderNumber][FailuresDataKeys.HAS_TIPS_FOR_DEV] = rowsWithTips.length;
            }

            state.data = fromJS(outerData);
            state.innerData = fromJS(dataToReturn);
        },
        setRawData(state, action: PayloadAction<any>) {
            state.data = action.payload;
            state.rowData = mapData(action.payload, (row) => row);
        },
        setInnerRawData(state, action: PayloadAction<any>) {
            state.innerData = action.payload;
        },
    },
});

export const { setTipInfo, setRawData, setInnerRawData } = tipsForDevelopmentSlice.actions;
export default tipsForDevelopmentSlice.reducer;
