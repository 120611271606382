import { useLocation } from "react-router-dom";
import styled from "styled-components";

import { PageNavigationItemIcon } from "components/layout/Page/PageNavigation/PageNavigationItemIcon";
import { PageNavigationSubTabItem } from "components/layout/Page/PageNavigation/PageNavigationSubTabItem";
import { PageNavigationItemLink } from "components/layout/Page/PageNavigation/page-navigation.types";

const PageNavigationSubTabsStyled = styled.div`
    margin: 0 3rem 0 3rem;

    display: flex;
    flex-wrap: wrap;

    background-color: ${({ theme }) => theme.color.white};

    @container page (max-width: ${({ theme }) => theme.breakpoints.xs}) {
        margin: 0;
    }
`;

type Props = {
    parentLink: string;
    items: PageNavigationItemLink[];
};

const PageNavigationSubTabs = (props: Props) => {
    const { parentLink, items } = props;

    const location = useLocation();

    return (
        <PageNavigationSubTabsStyled>
            {items.map((subPage, index) => {
                const pathname = [parentLink, subPage.to].filter((x) => x !== "").join("/");

                return (
                    <PageNavigationSubTabItem key={index} to={{ pathname, search: location.search }} end>
                        {subPage.title}
                        {subPage.icon && <PageNavigationItemIcon icon={subPage.icon} />}
                    </PageNavigationSubTabItem>
                );
            })}
        </PageNavigationSubTabsStyled>
    );
};

export { PageNavigationSubTabs };
