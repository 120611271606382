import { forwardRef, useMemo } from "react";

import { TableHead } from "components/ui/table-final-saviour/Table/TableHead/TableHead";
import { TableHeadProps } from "components/ui/table-final-saviour/Table/TableHead/table-head.types";

type Props = TableHeadProps & {
    zIndex?: number;
};

const TableHeadExpandable = forwardRef<HTMLTableSectionElement, Props>((props: Props, ref) => {
    const { style, zIndex = 1, ...otherProps } = props;

    const styleWithZIndex = useMemo(() => {
        return {
            ...style,
            zIndex,
        };
    }, [style, zIndex]);

    return <TableHead {...otherProps} ref={ref} style={styleWithZIndex} />;
});

TableHeadExpandable.displayName = "TableHeadExpandable";

export { TableHeadExpandable };
