import type { ComponentType, ReactNode } from "react";

import { TableCell as TableCellDefault, TableCellProps } from "components/ui/table-final-saviour/Table/TableCell";
import {
    TableHeaderCell as TableHeaderCellDefault,
    TableHeaderCellProps,
} from "components/ui/table-final-saviour/Table/TableHeaderCell";
import { TableHeaderCellWithTooltip } from "components/ui/table-final-saviour/Table/TableHeaderCell/TableHeaderCellWithTooltip";
import { DataColumnDataType } from "components/ui/table-final-saviour/Table/columns/table-columns.types";
import { Column, Row } from "components/ui/table-final-saviour/Table/table.types";
import { readCellValue } from "components/ui/table-final-saviour/Table/table.utils";
import { splitAndCapitalize } from "utils/string.utils";

type GetCellValueOptions<T extends Row> = {
    column: DataColumn<T>;
    row: T;
    value: T[keyof T];
};

export interface DataColumn<T extends Row> extends Column<T>, Pick<TableCellProps<T>, "align" | "fontStyle" | "width"> {
    _type: "data";
    dataKey: keyof T;
    dataType: DataColumnDataType;
    title?: string;
    titleTooltip?: ReactNode;
    getCellValue?: (options: GetCellValueOptions<T>) => React.ReactNode;
    components?: {
        TableCell?: ComponentType<TableCellProps<T>>;
        TableHeaderCell?: ComponentType<TableHeaderCellProps<T>>;
    };
}

export const createDataColumn = <T extends Row>(options: Omit<DataColumn<T>, "_type">): DataColumn<T> => {
    const { dataKey, components, titleTooltip } = options;

    const TableCell: ComponentType<TableCellProps<T>> = components?.TableCell ?? TableCellDefault;

    const getCellValue = options?.getCellValue ?? readCellValue;

    return {
        _type: "data",
        ...options,
        components: {
            TableCell: (props) => (
                <TableCell align={options?.align} width={options?.width} {...props}>
                    {getCellValue({
                        column: props.column as DataColumn<T>,
                        row: props.row,
                        value: props.row[dataKey],
                    })}
                </TableCell>
            ),
            TableHeaderCell: (props) => {
                const tableHeaderLabel = options?.title ?? splitAndCapitalize(String(dataKey));
                const customHeaderCell = !!components?.TableHeaderCell;

                if (!customHeaderCell && titleTooltip) {
                    return (
                        <TableHeaderCellWithTooltip
                            align={options?.align}
                            width={options?.width}
                            tooltip={titleTooltip}
                            {...props}
                        >
                            {tableHeaderLabel}
                        </TableHeaderCellWithTooltip>
                    );
                }

                const TableHeaderCell: ComponentType<TableHeaderCellProps<T>> =
                    components?.TableHeaderCell ?? TableHeaderCellDefault;

                return (
                    <TableHeaderCell align={options?.align} width={options?.width} {...props}>
                        {tableHeaderLabel}
                    </TableHeaderCell>
                );
            },
        },
    };
};
