import { ExpandedContentSectionContent } from "components/ui/table-final-saviour/Table/TableExpandable/ExpandedContent/Section/Content";
import { ExpandedContentSection as _ExpandedContentSection } from "components/ui/table-final-saviour/Table/TableExpandable/ExpandedContent/Section/ExpandedContentSection";
import { ExpandedContentSectionTable } from "components/ui/table-final-saviour/Table/TableExpandable/ExpandedContent/Section/ExpandedContentSectionTable";
import { ExpandedContentSectionTableExpandable } from "components/ui/table-final-saviour/Table/TableExpandable/ExpandedContent/Section/ExpandedContentSectionTableExpandable";

const ExpandedContentSection = _ExpandedContentSection as typeof _ExpandedContentSection & {
    Content: typeof ExpandedContentSectionContent;
    Table: typeof ExpandedContentSectionTable;
    TableExpandable: typeof ExpandedContentSectionTableExpandable;
};

ExpandedContentSection.Content = ExpandedContentSectionContent;
ExpandedContentSection.Table = ExpandedContentSectionTable;
ExpandedContentSection.TableExpandable = ExpandedContentSectionTableExpandable;

export { ExpandedContentSection };
