// @ts-strict-ignore
import { createContext, useEffect, useState } from "react";

import Paginator from "components/components-deprecated/tables/Paginator";
import Filters from "components/components-deprecated/tables/components/Filters";
import Headers from "components/components-deprecated/tables/components/Headers";
import Rows from "components/components-deprecated/tables/components/Rows";
import RowsInGroups from "components/components-deprecated/tables/components/RowsInGroups";
import {
    emptyData,
    emptyDataFromAllDataSources,
    emptyHiddenColumns,
} from "components/components-deprecated/tables/data-constants";
import { getHeadersForLevel } from "components/components-deprecated/tables/tables.helper";
import {
    BasicTableProps,
    TableContext as TableContextInterface,
} from "components/components-deprecated/tables/tables.interfaces";
import { Flex } from "components/ui-deprecated";
import { format as dataFormat, sort } from "deprecated/data-utils";
import { length, filter } from "deprecated/data-wrapper";
import { search as searchData, searchAndMerge } from "services/data.deprecated";

import "components/components-deprecated/tables/components/BasicTable.less";

const tableContext: TableContextInterface = {
    data: emptyData,
    dataFromAllDataSources: emptyDataFromAllDataSources,
    allDataIsError: false,
    allDataHasSpinner: false,
    allDataHasData: false,
    // FIXME
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    formatter: {},
    fieldsToLinks: {},
    expandable: false,
    doubleExpandable: false,
    expandableQueryData: null,
    expandableStaticData: null,
    pageSize: 10,
    currentPage: 0,
    orderField: null,
    orderDirection: "ASC",
    setOrderField: () => null,
    setOrderDirection: () => null,
    hiddenColumns: emptyHiddenColumns,
    customColumnTypes: {},
    setContextFromColumns: {},
    widerColumns: [],
    combineColumns: {},
    strictFilter: false,
    innerTableLayout: null,
    expandedInnerTableLayout: null,
    addHeaders: [],
};
export const TableContext = createContext(tableContext);
let timer;

const BasicTable: (props: BasicTableProps) => JSX.Element = (props: BasicTableProps) => {
    const {
        // --- Data --- //
        data,
        dataFromAllDataSources,
        allDataIsError,
        allDataHasSpinner,
        allDataHasData,
        hiddenColumns,
        fieldsToLinks,
        // --- Table Settings --- //
        defaultOrderField,
        defaultOrderDirection,
        format,
        expandable,
        doubleExpandable,
        expandedQueryParameters,
        expandableStaticData,
        search,
        padded,
        csvExportTable,
        // --- Pagination --- //
        pageSize,
        // --- Custom features --- //
        grouping,
        // --- Custom Columns ---//
        customColumnTypes,
        setContextFromColumns,
        filterColumns,
        commaSplitFilterColumns,
        multiSelectFilterColumns,
        contextFilters,
        booleanFilterColumns,
        widerColumns,
        combineColumns,
        strictFilter,
        addHeaders,
        // --- Custom Expansion --- //
        innerTableLayout,
        expandedInnerTableLayout,
    } = props;

    const formatter = dataFormat(format);

    /* --- States --- */
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [orderField, setOrderField] = useState(defaultOrderField);
    const [orderDirection, setOrderDirection] = useState(defaultOrderDirection);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
    const [filters, setFilters] = useState<Array<string>>([""]);

    // Create array for filters that need to cumulate data, e.g. multi select filter
    const [cumulativeFilters, setCumulativeFilters] = useState<Array<string>>([]);

    /* --- Expansion --- */
    // Correct type of hiddenColumns to account for levels in case of expansion
    const structureHiddenColumns = { ...emptyHiddenColumns };

    function setDefaultStructure() {
        for (let i = 0; i < 3; i++) {
            structureHiddenColumns[`level${i}`] =
                hiddenColumns[`level${i}`] !== undefined && Array.isArray(hiddenColumns[`level${i}`])
                    ? hiddenColumns[`level${i}`]
                    : [];
        }
    }
    if (!expandable) {
        // Is there not a better way to check that this matches the HiddenColumns interface?
        if (!("level0" in hiddenColumns && "level1" in hiddenColumns && "level2" in hiddenColumns)) {
            if (Array.isArray(hiddenColumns)) {
                structureHiddenColumns["level0"] = [...hiddenColumns];
            }
        } else {
            setDefaultStructure();
        }
    } else {
        setDefaultStructure();
    }

    /* --- Printing --- */
    const isPrintMode = false;
    const isPrePrintMode = false;

    /* --- Sorting, Search & Filters --- */
    const [booleanFilters, setBooleanFilters] = useState({ ...booleanFilterColumns });
    const sortedData =
        orderField === null
            ? data
            : sort([
                  {
                      fieldName: orderField,
                      order: orderDirection,
                  },
              ])(data);

    let filteredData = sortedData;
    const [searchedResults, setSearchedResults] = useState(filteredData);

    const allFilterColumns = [...filterColumns, ...multiSelectFilterColumns, ...commaSplitFilterColumns];

    filters.map((item, i) => {
        if (item === "Not empty") {
            filteredData = filter(
                filteredData,
                (row) => row[allFilterColumns[i]] !== null && row[allFilterColumns[i]] !== ""
            );
        } else {
            filteredData = searchData(item, filteredData, formatter, Object.keys(data.data), [], strictFilter);
        }
    });

    // Merge many searches instead of narrowing down search by search
    filteredData = searchAndMerge(
        cumulativeFilters,
        filteredData,
        formatter,
        multiSelectFilterColumns,
        [],
        strictFilter
    );

    /* --- Boolean Filters --- */
    // All the data should be displayed when all boolean filters are active
    if (!Object.values(booleanFilters).every((val) => val === true)) {
        for (const key in booleanFilters) {
            if (booleanFilters[key] === true) {
                filteredData = filter(filteredData, (row) => {
                    return Boolean(row[key]) === booleanFilters[key];
                });
            }
        }
    }

    /* --- Finalize Data and Headers --- */
    const displayData = searchTerm.length > 0 ? searchedResults : filteredData;
    const headers =
        displayData !== undefined
            ? getHeadersForLevel(displayData, structureHiddenColumns, 0, grouping, addHeaders)
            : [];

    /* --- Effects --- */
    useEffect(() => {
        setCumulativeFilters([]);
        clearTimeout(timer);
        timer = setTimeout(() => {
            setDebouncedSearchTerm(searchTerm);
        }, 1500);
    }, [searchTerm]);

    useEffect(() => {
        setSearchedResults(searchData(debouncedSearchTerm, filteredData, formatter, headers, []));
        filteredData = searchData(debouncedSearchTerm, filteredData, formatter, headers, []);
    }, [debouncedSearchTerm]);

    /* --- Context --- */
    const initContext = {
        data: displayData,
        dataFromAllDataSources: dataFromAllDataSources,
        allDataIsError: allDataIsError,
        allDataHasSpinner: allDataHasSpinner,
        allDataHasData: allDataHasData,
        formatter: formatter,
        fieldsToLinks: fieldsToLinks,
        pageSize: pageSize,
        currentPage: currentPage,
        orderField: orderField,
        orderDirection: orderDirection,
        setOrderField: setOrderField,
        setOrderDirection: setOrderDirection,
        expandable: expandable,
        doubleExpandable: doubleExpandable,
        expandedQueryParameters: expandedQueryParameters,
        expandableStaticData: expandableStaticData,
        hiddenColumns: structureHiddenColumns,
        customColumnTypes: customColumnTypes,
        setContextFromColumns: setContextFromColumns,
        widerColumns: widerColumns,
        combineColumns: combineColumns,
        strictFilter: strictFilter,
        innerTableLayout: innerTableLayout,
        expandedInnerTableLayout: expandedInnerTableLayout,
        addHeaders: addHeaders,
    };

    return (
        <div className={`basic-table-wrapper ${padded ? "padded" : ""}`}>
            <TableContext.Provider value={initContext}>
                <Filters
                    data={data}
                    filteredData={displayData}
                    search={search}
                    setCurrentPage={setCurrentPage}
                    filterColumns={filterColumns}
                    commaSplitFilterColumns={commaSplitFilterColumns}
                    multiSelectFilterColumns={multiSelectFilterColumns}
                    contextFilters={contextFilters}
                    booleanFilters={booleanFilters}
                    setBooleanFilters={setBooleanFilters}
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    filters={filters}
                    setFilters={setFilters}
                    cumulativeFilters={cumulativeFilters}
                    setCumulativeFilters={setCumulativeFilters}
                    csvExportTable={csvExportTable}
                />
                {length(displayData) > 0 ? (
                    <Flex className={"basic-table-container"} flexDirection={"column"}>
                        {/* --- Head ---*/}
                        {<Headers level={0} data={displayData} grouping={grouping} />}
                        {/* --- Body --- */}
                        {grouping ? <RowsInGroups /> : <Rows data={displayData} level={0} />}
                    </Flex>
                ) : (
                    <div> No data to display. </div>
                )}
                {length(displayData) > pageSize && !isPrintMode && !isPrePrintMode && (
                    <Paginator
                        currentPage={currentPage}
                        dataLength={length(displayData)}
                        pageSize={pageSize}
                        setPage={setCurrentPage}
                    />
                )}
            </TableContext.Provider>
        </div>
    );
};
export default BasicTable;
