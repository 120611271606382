// @ts-strict-ignore
import { WidgetFrame } from "components/layout/WidgetFrame";
import { WidgetWithQueryParameters } from "components/layout/WidgetFrame/widget.types";
import {
    KPIDataSourceChartData,
    KPI,
    KPIDataSourceTextData,
    toKPIChartDataPoints,
    toKPITextData,
} from "components/ui/KPI";
import { useDataSource } from "hooks/useDataSource";

type Props = WidgetWithQueryParameters<"dateFrom" | "dateTo">;

const SparePartsCostPerAstronautKPIWidget = (props: Props) => {
    const { queryParameters } = props;

    const dataSourceParameters = { ...queryParameters, KPI: 6 };

    const chartDataSource = useDataSource<KPIDataSourceChartData[]>(
        "Dashboard_ReportsPerDevice_Chart",
        dataSourceParameters
    );
    const textDataSource = useDataSource<KPIDataSourceTextData[]>(
        "Dashboard_ReportsPerDevice_Text",
        dataSourceParameters
    );
    const dataSources = [chartDataSource, textDataSource];

    const { data, lastDataPoint } = toKPIChartDataPoints(chartDataSource?.data ?? []);
    const additionalValue = toKPITextData(textDataSource?.data ?? []);

    return (
        <>
            <WidgetFrame.Title title={"Spare Parts Cost per Astronaut"} icon={"lely-icon-category"} size={"sm"}>
                <WidgetFrame.Title.Information lastImportType={"middleware"} pageId={335577309} />
            </WidgetFrame.Title>
            <WidgetFrame.KPI dependencies={dataSources}>
                <KPI.LastValue title="Last Selected Full Month" value={lastDataPoint?.label} />
                {additionalValue && <KPI.AdditionalValue title={"Target"} value={additionalValue} />}
                <KPI.Chart data={data} />
            </WidgetFrame.KPI>
        </>
    );
};

export { SparePartsCostPerAstronautKPIWidget };
