import styled from "styled-components";

import { Loader } from "components/ui/Loader";

const LoaderContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    margin: 1rem 0;
`;

const LoaderStyled = styled(Loader)`
    font-size: 1.25rem;
    color: var(--wds-color-icon);
`;

const LoaderText = styled.div`
    font-size: 1rem;
    color: var(--wds-color-text-lighter);
`;

const DocumentationWindowContentLoading = () => {
    return (
        <LoaderContainer>
            <LoaderStyled />
            <LoaderText>Loading documentation page...</LoaderText>
        </LoaderContainer>
    );
};

export { DocumentationWindowContentLoading };
