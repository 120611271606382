// @ts-strict-ignore
import { ChartConfiguration } from "components/ui/Chart/chart.types";
import { baseChartOptions } from "components/ui/Chart/configurations/chart-configurations/base.chart-configuration";
import { createLegendConfiguration, createTooltipConfiguration } from "components/ui/Chart/configurations/plugins";

const bubbleChartConfiguration = {
    type: "bubble",
    options: {
        ...baseChartOptions,
        scales: {},
        plugins: {
            legend: createLegendConfiguration(),
            tooltip: createTooltipConfiguration(),
        },
    },
} satisfies ChartConfiguration<"bubble">;

type Options = {
    scales: ChartConfiguration<"bubble">["options"]["scales"];
    plugins?: ChartConfiguration<"bubble">["options"]["plugins"];
};

export const createBubbleChartConfiguration = (options: Options): ChartConfiguration<"bubble"> => ({
    ...bubbleChartConfiguration,
    options: {
        ...bubbleChartConfiguration.options,
        scales: options.scales,
        plugins: {
            ...bubbleChartConfiguration.options.plugins,
            ...options.plugins,
        },
    },
});
