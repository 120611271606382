// @ts-strict-ignore
import { ReactNode, useState } from "react";
import * as ReactDOM from "react-dom";

export type PortalProps = {
    zIndex: number;
    children: ReactNode;
    dropUp?: boolean;
    portalHeightPx?: number;
};

Portal.defaultProps = {
    zIndex: 1000,
    dropUp: false,
    portalHeightPx: 0,
};

export default function Portal({ children, zIndex, dropUp, portalHeightPx }: PortalProps) {
    const [relativeDiv, setRelativeDiv] = useState<HTMLDivElement | null>(null);
    const bodyElement = document.body;

    if (bodyElement !== null) {
        return (
            <>
                <div ref={(div) => div && setRelativeDiv(div)} />
                {ReactDOM.createPortal(
                    <div style={getStyle(relativeDiv, zIndex, dropUp, portalHeightPx)}>{children}</div>,
                    bodyElement
                )}
            </>
        );
    } else {
        throw new Error("In the html is not body element.");
    }
}

function getStyle(div: null | HTMLDivElement, zIndex: number, dropUp: boolean, portalHeightPx: number): any {
    if (div === null) {
        return {
            position: "fixed",
            pointerEvents: "auto",
            zIndex,
        };
    } else {
        const rect = div.getBoundingClientRect();
        const distanceFromBottom = window.innerHeight - rect.top;
        const shouldDropUp = dropUp && portalHeightPx > 0 && distanceFromBottom < portalHeightPx;
        const top = shouldDropUp ? rect.top - portalHeightPx : rect.top;
        return {
            position: "fixed",
            top: `${top}px`,
            left: `${rect.left}px`,
            width: `${rect.width}px`,
            zIndex,
            boxShadow: shouldDropUp ? "0 2px 5px #dedbd666" : "",
            background: shouldDropUp ? "white" : "",
            pointerEvents: "auto",
        };
    }
}
