import type { FlatNamespace } from "i18next";

import csCareCheck from "locales/cs/care-check.json";
import cs from "locales/cs/resource.json";
import daCareCheck from "locales/da/care-check.json";
import da from "locales/da/resource.json";
import deCareCheck from "locales/de/care-check.json";
import de from "locales/de/resource.json";
import enCareCheck from "locales/en/care-check.json";
import en from "locales/en/resource.json";
import esCareCheck from "locales/es/care-check.json";
import es from "locales/es/resource.json";
import etCareCheck from "locales/et/care-check.json";
import et from "locales/et/resource.json";
import fiCareCheck from "locales/fi/care-check.json";
import fi from "locales/fi/resource.json";
import frCareCheck from "locales/fr/care-check.json";
import fr from "locales/fr/resource.json";
import huCareCheck from "locales/hu/care-check.json";
import hu from "locales/hu/resource.json";
import itCareCheck from "locales/it/care-check.json";
import it from "locales/it/resource.json";
import jaCareCheck from "locales/ja/care-check.json";
import ja from "locales/ja/resource.json";
import koCareCheck from "locales/ko/care-check.json";
import ko from "locales/ko/resource.json";
import { Language } from "locales/languages.enum";
import ltCareCheck from "locales/lt/care-check.json";
import lt from "locales/lt/resource.json";
import lvCareCheck from "locales/lv/care-check.json";
import lv from "locales/lv/resource.json";
import nlCareCheck from "locales/nl/care-check.json";
import nl from "locales/nl/resource.json";
import noCareCheck from "locales/no/care-check.json";
import no from "locales/no/resource.json";
import plCareCheck from "locales/pl/care-check.json";
import pl from "locales/pl/resource.json";
import ptCareCheck from "locales/pt/care-check.json";
import pt from "locales/pt/resource.json";
import ruCareCheck from "locales/ru/care-check.json";
import ru from "locales/ru/resource.json";
import svCareCheck from "locales/sv/care-check.json";
import sv from "locales/sv/resource.json";
import trCareCheck from "locales/tr/care-check.json";
import zhCareCheck from "locales/zh/care-check.json";
import zh from "locales/zh/resource.json";

export const namespaces = ["translation", "careCheck"] as const;

interface Resource {
    [key: string]: string | Resource;
}

export const resources = {
    cs: {
        translation: cs,
        careCheck: csCareCheck,
    },
    da: {
        translation: da,
        careCheck: daCareCheck,
    },
    de: {
        translation: de,
        careCheck: deCareCheck,
    },
    en: {
        translation: en,
        careCheck: enCareCheck,
    },
    es: {
        translation: es,
        careCheck: esCareCheck,
    },
    et: {
        translation: et,
        careCheck: etCareCheck,
    },
    fi: {
        translation: fi,
        careCheck: fiCareCheck,
    },
    fr: {
        translation: fr,
        careCheck: frCareCheck,
    },
    hu: {
        translation: hu,
        careCheck: huCareCheck,
    },
    it: {
        translation: it,
        careCheck: itCareCheck,
    },
    ja: {
        translation: ja,
        careCheck: jaCareCheck,
    },
    ko: {
        translation: ko,
        careCheck: koCareCheck,
    },
    lt: {
        translation: lt,
        careCheck: ltCareCheck,
    },
    lv: {
        translation: lv,
        careCheck: lvCareCheck,
    },
    nl: {
        translation: nl,
        careCheck: nlCareCheck,
    },
    no: {
        translation: no,
        careCheck: noCareCheck,
    },
    pl: {
        translation: pl,
        careCheck: plCareCheck,
    },
    pt: {
        translation: pt,
        careCheck: ptCareCheck,
    },
    ru: {
        translation: ru,
        careCheck: ruCareCheck,
    },
    sv: {
        translation: sv,
        careCheck: svCareCheck,
    },
    tr: {
        translation: en, // there are no Turkish translations for FMS
        careCheck: trCareCheck,
    },
    zh: {
        translation: zh,
        careCheck: zhCareCheck,
    },
} satisfies {
    [_language in Language]: {
        [_namespace in FlatNamespace]: Resource;
    };
};
