import { useEffect } from "react";

import { useAppDispatch } from "store";
import { setConfluencePageId } from "store/slices/documentation.slice";

const baseConfluenceUrl = "https://datamole.atlassian.net/wiki/";

/**
 * Intercept all clicks on the page and check if the clicked link is a confluence page.
 */
export const useInterceptPageLinks = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        const interceptLinks = (event: MouseEvent) => {
            const target = event.target as HTMLElement;
            if (target.tagName !== "A") return;

            const href = target.getAttribute("href");
            if (!href) return;

            // Check if the link is a confluence page and get it's id
            const regex = new RegExp(String.raw`${baseConfluenceUrl}spaces/LSSA2documentation/pages/(\d+)`, "g");
            const match = regex.exec(href);
            if (!match) return;

            // Instead of navigating to the page, set the page id in the store
            event.preventDefault();

            const pageId = Number(match[1]);
            dispatch(setConfluencePageId(pageId));
        };

        document.addEventListener("click", interceptLinks);
        return () => document.removeEventListener("click", interceptLinks);
    }, []);
};
