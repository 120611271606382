import styled from "styled-components";

import { config, ENVIRONMENTS } from "config";
import { theme } from "styles/theme";

type DisplayOnEnv = Extract<ENVIRONMENTS, "development" | "integration" | "acceptance">;

const backgroundColorMap = {
    development: theme.color.gray,
    integration: theme.color.yellow,
    acceptance: theme.color.orange,
} satisfies Record<DisplayOnEnv, string>;

const colorMap = {
    development: theme.color.white,
    integration: theme.color.lelyRedDark,
    acceptance: theme.color.lelyRedDark,
} satisfies Record<DisplayOnEnv, string>;

const SidebarEnvIndicatorStyled = styled.div<{ $env: DisplayOnEnv }>`
    position: absolute;
    top: 1rem;
    right: 1rem;

    padding: 0.25rem;

    background-color: ${({ $env }) => backgroundColorMap[$env]};
    border-radius: 0.25rem;

    color: ${({ $env }) => colorMap[$env]};

    font-size: 0.75rem;
    font-weight: 500;
    letter-spacing: 0.05rem;
    line-height: 1;
`;

const SidebarEnvIndicator = () => {
    const env = config.APP_ENV;
    if (env !== "development" && env !== "integration" && env !== "acceptance") return null;

    const value = env.slice(0, 3).toUpperCase();

    return <SidebarEnvIndicatorStyled $env={env}>{value}</SidebarEnvIndicatorStyled>;
};

export { SidebarEnvIndicator };
