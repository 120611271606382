import DOMPurify from "dompurify";

import { dayjs } from "components/ui/DatePicker/date.utils";
import {
    ConfluencePageViewContainer,
    ConfluencePageViewContent,
    ConfluencePageViewHeader,
    ConfluencePageViewHeaderLastUpdated,
    ConfluencePageViewHeaderTitle,
} from "features/documentation/DocumentationWindow/ConfluencePageView/confluence-page-view.styled-components";
import { useInterceptPageLinks } from "features/documentation/hooks/useInterceptPageLinks";
import { ConfluencePage } from "store/services/confluence.service";

type Props = {
    data: ConfluencePage;
};

const ConfluencePageView = (props: Props) => {
    const { data } = props;
    const contentPurified = DOMPurify.sanitize(data.content);

    useInterceptPageLinks();

    return (
        <ConfluencePageViewContainer>
            <ConfluencePageViewHeader>
                <ConfluencePageViewHeaderTitle>{data.title}</ConfluencePageViewHeaderTitle>
                <ConfluencePageViewHeaderLastUpdated>
                    Last updated: {dayjs(data.updatedAt).format("dddd, Do MMM YYYY, HH:mm")}
                </ConfluencePageViewHeaderLastUpdated>
            </ConfluencePageViewHeader>
            <ConfluencePageViewContent dangerouslySetInnerHTML={{ __html: contentPurified }} />
        </ConfluencePageViewContainer>
    );
};

export { ConfluencePageView };
