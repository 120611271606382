// @ts-strict-ignore
import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import * as Yup from "yup";

import { SidebarControlContentActions } from "components/layout/Sidebar/SidebarControls/SidebarControl/SidebarControlContent/SidebarControlContentActions";
import { SidebarControlContentTitle } from "components/layout/Sidebar/SidebarControls/SidebarControl/SidebarControlContent/SidebarControlContentTitle";
import { primaryRolesSelector } from "components/layout/Sidebar/SidebarControls/UserRoleSelect/user-role-selector.utils";
import { SidebarControlToggleFunctionProp } from "components/layout/Sidebar/sidebar.types";
import { Button } from "components/ui/Button";
import { notification, SelectField, TreeMultiSelect } from "components/ui-deprecated";
import { useSendUserEvent } from "hooks/user-event/useSendUserEvent";
import { useAppSelector } from "store";
import { usePutUserSettingsMutation } from "store/services/user.service";
import { setPrimaryActiveRoleId, setSecondaryActiveRoleIds } from "store/slices/user.slice";
import { UserSettingPrimaryActiveRoleIdType, UserSettingSecondaryActiveRolesIdsType } from "types/user.types";

type FormValues = {
    primaryRole: string;
    secondaryRoles: string[];
};

const validationSchema = Yup.object({
    primaryRole: Yup.string(),
    secondaryRoles: Yup.array(Yup.string()),
});

const FormFields = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const UserRoleSelectContent = ({ handleToggleOpen }: SidebarControlToggleFunctionProp) => {
    const userId = useAppSelector((state) => state.user.id);

    const primaryRoles = useAppSelector(primaryRolesSelector);

    const secondaryRoles = useAppSelector((state) => state.user.secondaryRoles);
    const primaryActiveRoleId = useAppSelector((state) => state.user.primaryActiveRoleId);
    const secondaryActiveRolesIds = useAppSelector((state) => state.user.secondaryActiveRolesIds);
    const dispatch = useDispatch();

    const [updateUserSettings] = usePutUserSettingsMutation();
    const sendUserEvent = useSendUserEvent();

    const handleSubmit = async (data: FormValues) => {
        try {
            // Save the user settings in DB
            const paramsPrimaryRole = {
                name: "primaryActiveRoleId",
                value: data.primaryRole,
            } satisfies UserSettingPrimaryActiveRoleIdType;
            const paramsSecondaryRoles = {
                name: "secondaryActiveRolesIds",
                value: data.secondaryRoles.join(","), // Roles are stored as a comma-separated string
            } satisfies UserSettingSecondaryActiveRolesIdsType;
            await Promise.all([
                updateUserSettings({ userId, params: paramsPrimaryRole }),
                updateUserSettings({ userId, params: paramsSecondaryRoles }),
            ]);

            // Update the user settings in the store
            dispatch(setPrimaryActiveRoleId(data.primaryRole));
            dispatch(setSecondaryActiveRoleIds(data.secondaryRoles));
            handleToggleOpen();

            // Collect the role change event
            const primaryRole = primaryRoles.find((r) => r.id === data.primaryRole)?.name;
            sendUserEvent({
                type: "select:primary-role",
                value: primaryRole ?? data.primaryRole,
            });
        } catch (error) {
            console.error("Error updating user settings", error);
            notification.danger("Settings update failed. Please try again.");
        }
    };

    return (
        <Formik<FormValues>
            initialValues={{
                primaryRole: primaryActiveRoleId ?? "",
                secondaryRoles: secondaryActiveRolesIds ?? [],
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
        >
            {({ dirty }) => (
                <Form>
                    <SidebarControlContentTitle>
                        Role Select
                        <SidebarControlContentActions>
                            <Button variant={"text"} type={"reset"} disabled={!dirty}>
                                Reset
                            </Button>
                            <Button type={"submit"} disabled={!dirty}>
                                Apply
                            </Button>
                        </SidebarControlContentActions>
                    </SidebarControlContentTitle>

                    <FormFields>
                        <SelectField
                            name={"primaryRole"}
                            label={"Primary role"}
                            placeholder={"Select primary role"}
                            options={primaryRoles.map((r) => ({
                                value: r.id,
                                text: r.name,
                            }))}
                        />
                        <TreeMultiSelect
                            name={"secondaryRoles"}
                            label={"Secondary roles"}
                            options={secondaryRoles.map((role) => ({
                                key: role.id,
                                value: role.id,
                                title: role.name,
                            }))}
                        />
                    </FormFields>
                </Form>
            )}
        </Formik>
    );
};

export { UserRoleSelectContent };
